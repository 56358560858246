import React, { useEffect, useState } from 'react'
import ProductModal from '../../dashboard/components/UI/ProductModal';
import axios from 'axios';
import { getToken } from '../../actions/auth';
import Loading from '../ui/Loading';
import ReactPaginate from 'react-paginate';
import moment from 'moment/moment';

export default function SubmitCaseList(props) {
    const { setsubmitCaseList } = props
    const [caseListValue, setCaseListValue] = useState([])
    const [loader, setLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(15);
    const close = () => {
        setsubmitCaseList(false)
    }
    function handlePageClick({ selected: selectedPage }) {
        setCurrentPage(selectedPage);
        console.log(setRowPerPage)
    }

    const pageCount = Math.ceil(
        Array.isArray(caseListValue)
            ? caseListValue.length / rowPerPage
            : 0 / rowPerPage
    )
    const offset = currentPage * rowPerPage;

    useEffect(() => {
        caseList()
    }, [])

    const caseList = async () => {
        setLoader(true)
        try {
            const { token, userToken } = getToken();
            const response = await axios.get(
                `/api/sf/submit-case`,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            console.log('Caselist', response.data)
            setCaseListValue(response.data)
        } catch (error) {
            console.log("Error:", error);
        }
        setLoader(false)
    }

    return (
        <div>
            <ProductModal
                heading="Submit Case List"
                closeModal={close}
            >
                <div className="product-company-detail">
                    <div className="company_system_table">
                        {loader === true ? <Loading /> : <div className="tbl_container tabmars">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="tbl-td-th">Case Number</th>
                                        <th className="tbl-td-th">Subject</th>
                                        <th className="tbl-td-th">Description</th>
                                        <th className="tbl-td-th">Current Support</th>
                                        <th className="tbl-td-th">Resolution</th>
                                        <th className="tbl-td-th">Status</th>
                                        <th className="tbl-td-th">Created Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(caseListValue) &&
                                        caseListValue
                                            .slice(offset, offset + rowPerPage)
                                            .map((item, index) => {
                                                const dateString = item.CreatedDate;
                                                const formattedDate = moment(dateString).format('DD/MM/YYYY');
                                                return (
                                                    <tr key={index}>
                                                        <td className="tbl-td-th">{item.CaseNumber}</td>
                                                        <td className="tbl-td-th">{item.Subject}</td>
                                                        <td className="tbl-td-th">{item.Description}</td>
                                                        <td className="tbl-td-th">{item.Current_Support_Rep__c}</td>
                                                        <td className="tbl-td-th">{item.Resolution__c}</td>
                                                        <td className="tbl-td-th">{item.Status}</td>
                                                        <td className="tbl-td-th">{formattedDate}</td>
                                                    </tr>
                                                );
                                            })}
                                </tbody>
                            </table>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                containerClassName={"pagination-container"}
                                previousLinkClassName={"pagination__link"}
                                nextLinkClassName={"pagination__link"}
                                disabledClassName={"disabled"}
                                activeClassName={"active"}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                            />
                        </div>}
                    </div>
                </div>
            </ProductModal>
        </div>
    )
}
