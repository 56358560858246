import React, { useState } from 'react';
import Loading from './ui/Loading';
import axios from 'axios';
import { getToken } from '../actions/auth';
import toast from 'react-hot-toast';
import { Toaster } from "react-hot-toast";

const CloudPunchSetup = () => {
    const [formData, setFormData] = useState({
        name: 'CloudPunch-x64',
        version: '',
        file: null
    });
    const [isLoading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, file });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('version', formData.version);
        formDataToSend.append('installation_setup', formData.file);

        try {
            const { token, userToken } = getToken();
            const response = await axios.post(
                '/api/tlm/cp/installations/setup/upload',
                formDataToSend,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setLoading(false);
            if (!response.ok) {
                throw new Error('Failed to upload setup file');
            }
            toast.success("Upload Successfully !")
            // Handle successful upload
            console.log('Setup file uploaded successfully');
        } catch (error) {
            toast.error("Uploading Failed !")
            console.error('Error uploading setup file:', error);
            setLoading(false);
        }
    };

    return (
        <div>
            <div className='modalContainer'>
                <div className='mainContentCustom2'>
                    <fieldset className='customFieldSet'>
                        <legend>Setup</legend>
                        <form onSubmit={handleSubmit}>
                            <div className='formFieldCustom'>
                                <label htmlFor="setupName">Name</label>
                                <select name="name" className='setupname' value={formData.name} onChange={handleChange}>
                                    <option>CloudPunch-x64</option>
                                    <option>CloudPunch-x86</option>
                                </select>
                            </div>
                            <div className='formFieldCustom'>
                                <label htmlFor="setupVersion">Version</label>
                                <input
                                    type="text"
                                    id="setupVersion"
                                    name="version"
                                    value={formData.version}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {isLoading ? <Loading /> : <>
                                <div className='formFieldCustom'>
                                    <label htmlFor="setupFile">File Upload</label>
                                    <div className="drag-area">
                                        <div className="icon"><i className="fas fa-cloud-upload-alt"></i></div>
                                        <header >Drag &amp; Drop to Upload File</header>
                                        <span>OR</span>
                                        <input type="file" onChange={handleFileChange} accept=".zip" className='Cstmfileuploadinput' />
                                    </div>
                                </div>
                                <div className='flexCenterCustom'>
                                    <button type="submit" className='saveInstallationsetupBtn'>Upload</button>
                                </div>
                            </>}
                        </form>
                    </fieldset>
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default CloudPunchSetup;
