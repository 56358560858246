import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { getToken } from "../actions/auth";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useToggle from "../dashboard/hooks/useToggle";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Loading from "./ui/Loading";
import ReactQuill from "react-quill";
import Modal from "./ui/Modal";
import closeppt from './../assets/images/Crossppt.svg'
import 'react-quill/dist/quill.snow.css';
const MineralBody = (props) => {
  const location = props.location;
  const { userInfo, productId, permission } = props;
  const [isLoading, setLoading] = useState(false);
  const [editing, handleEditing] = useToggle(false);
  const [show, setShow] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [value, setValue] = useState('')
  const [isToggle, setIsToggle] = useState(false);
  const [Lodding, setLodding] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const [customField, setCustomField] = useState([{ custom_url: '' }]);
  const [selectedVideo, setSelectedVideo] = useState('')
  const [permissionUser, setPermissionUser] = useState(permission)
  const isSaveDisabled = customField.some(item => !item.custom_url);

  useEffect(() => {
    const userAdminPermission = localStorage.getItem('userAdminPermission')
    setPermissionUser(userAdminPermission)
  }, [permissionUser])

  const handleSubmit = async () => {
    try {
      const { token, userToken } = getToken();
      setLoading(true);
      const requestBody = {
        //username: "pradeept@chetu.com",
        // username: userInfo.username,
        username: userInfo.email,
      };

      const response = await axios.post(
        "/api/products/mineral/sso",
        requestBody,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );

      setLoading(false);
      toast.success("Connected!");
      window.open(response.data);
    } catch (error) {
      toast.error("Unauthorised User");
      setLoading(false);
    }
  };
  ////
  const slidervalue = responseData && responseData.custom_urls && responseData.custom_urls.length
  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: slidervalue <= 2 ? 1 : 3,
    slidesToScroll: 1,
    initialSlide: 0,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,

        settings: {
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 700,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  ///description///
  const handleChange = (value) => {
    setValue({ description: value });
  };
  const handleEditAble = (event) => {
    event.stopPropagation();
    handleEditing();
  };
  const handleSave = async (event) => {
    event.stopPropagation();
    try {
      const { token, userToken } = getToken();
      // setLoading(true);
      var formData = {
        product_id: JSON.stringify(productId),
        // description: formValue.description
        description: value.description
      }
      await axios.post(
        "/api/product/external_desc",
        formData,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      // setLoading(false);
      toast.success("Updated Successfully!")
      handleEditing();
    } catch (error) {
      toast.error("Invalid request error")
    }
  }

  useEffect(() => {
    GetDescription()
  }, [refresh])

  const GetDescription = async () => {
    try {
      const { token, userToken } = getToken();
      const response = await axios.get(
        `/api/product/external_desc/${productId}`,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      setValue(response.data)
      setResponseData(response.data)
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];
  const SelectVideo = (e) => {
    const image = e.target
    if (image.alt === "biz_library_image") {
      setSelectedVideo(image.src)
    }
    else if (image.parentNode.parentNode.querySelector('source').type === "video/mp4") {
      const videoSrc = e.target.parentNode.parentNode.querySelector('source').src;
      setSelectedVideo(videoSrc)
    }
    setShow(true)
  }
  const ModalClose = () => {
    setShow(false)
  }

  const handleToggle = () => {
    setIsToggle(!isToggle);
  };

  const handleAddCustomField = () => {
    setCustomField([...customField, { custom_url: "" }]);
  };

  const handleCustomChange = (e, index) => {
    const { files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      const fileSizeInMB = file.size / (1024 * 1024);

      if (fileSizeInMB > 10) {
        toast.error('File is too large. Please select a file smaller than 10 MB.');
        return;
      }

      const fileExtension = file.name.split('.').pop().toLowerCase();
      const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'mp4', 'avi', 'mov', 'wmv', 'webm'];

      if (!validExtensions.includes(fileExtension)) {
        toast.error('Invalid file type. Please select an image or video file.');
        return;
      }
      const copy = [...customField];
      copy[index].custom_url = file;
      setCustomField(copy);
    }
  };

  const handleDeleteCustomField = async (index, row) => {
    const copy = [...customField];
    copy.splice(index, 1);
    setCustomField(copy);
  };

  const SendUrlData = async () => {
    try {
      const { token, userToken } = getToken();
      setLodding(true);
      const formData = new FormData();
      formData.append('product_id', JSON.stringify(productId));

      customField.forEach(({ custom_url }) => {
        formData.append(`custom_urls`, custom_url);
      });
      await axios.post(
        "/api/product/add/external_desc/urls",
        formData,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      setLodding(false);
      setRefresh(!refresh)
      toast.success("Add Successfully!")
      setCustomField([{ custom_url: '' }])
      setIsToggle(false)
    } catch (error) {
      setLodding(false);
      toast.error("Invalid request error")
    }
  }

  const RemovedImgAndVideo = async (e, row) => {
    try {
      const { token, userToken } = getToken();
      setLodding(true);
      const formData = new FormData();
      formData.append('product_id', JSON.stringify(productId));

      formData.append('url', row.url)
      await axios.post(
        "/api/product/remove/external_desc/urls",
        formData,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      setLodding(false);
      toast.success("Removed Successfully!")
      setRefresh(!refresh)
    } catch (error) {
      setLodding(false);
      toast.error("Invalid request error")
    }
  }

  const handleKeyPress = (event) => {
    switch (event.key) {
      case ' ':
        if (value.description.trim() === '<p><br></p>' ||
          value.description.trim() === "<h2><br></h2>" ||
          value.description.trim() === "<p> </p>" ||
          value.description.trim() === '') {
          setValue({ description: '' });
        }
        break;

      case 'Enter':
        if (value.description.trim() === '<p><br></p>' ||
          value.description.trim() === "<p> </p>" ||
          value.description === '<p> </p><p><br></p>' ||
          value.description.trim() === '<p><br></p><p><br></p>' ||
          value.description.trim() === '<h2><br></h2><p><br></p>') {
          setValue({ description: '' });
          event.preventDefault();
        }
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div className="mineral-main-sec">
        <div className="mineral-sec">
          <button className="btn-mineral " onClick={handleSubmit}>
            {isLoading ? "Connecting..." : "Login to Mineral"}
          </button>
        </div>
        <div className="mineral-img">
          <img src={location.state && location.state.image_name} alt="img" />
        </div>
      </div>
      <div className="mineral-dynamic-desc">
        <p>{location.state && location.state.product_description}</p>
      </div>
      <div className={`${editing ? 'mineral-desc' : 'mineral-desc-min'}`}>
        {permissionUser === 'true' && (
          <div>
            {editing ? (
              <>
                <button
                  title="Save"
                  className="me-1"
                  onClick={handleSave}
                  disabled={!value || value.description === "<h2><br></h2>"
                    || value.description === "" || value.description === '<p> </p>' || value.description === '<p><br></p>'}
                >
                  Save
                </button>
              </>
            ) : (
              <span
                title="Edit"
                className="edit-icon-btn-mineral"
                onClick={handleEditAble}
              >
                <FontAwesomeIcon icon={solid("pen-to-square")} />
              </span>
            )}
          </div>
        )}
        <div className="card-bar-one">
          <div className="card-content min_biz">
            {responseData.length === 0 ? <Loading /> :
              <div>
                {editing === false ? <div dangerouslySetInnerHTML={{ __html: value.description }} /> :
                  <div className="description">
                    <ReactQuill style={{ height: '70%' }}
                      theme="snow"
                      onKeyDown={handleKeyPress}
                      className={`prod-description ${editing === false ? "active z-index-99" : ""
                        }`}
                      value={value && value.description}
                      name="description"
                      onChange={handleChange}
                      modules={modules}
                      formats={formats}
                    />
                  </div>}
              </div>}
          </div>
        </div>
      </div>
      {/* start */}
      {show === true ?
        <div className="product-modal productmodal-full product-modal-center">
          <div className="product-modal-inner">

            <div className="user-provisional-outer">
              <div className="product-modal-head">
                <span
                  className="product-close-modal-btn"
                  onClick={ModalClose}
                >
                  <FontAwesomeIcon icon={solid("xmark")} />
                </span>
              </div>
              <div className="product-modal-body">
                {selectedVideo.endsWith('.png') || selectedVideo.endsWith('.PNG') || selectedVideo.endsWith('.jpg') ||
                  selectedVideo.endsWith('.JPG') || selectedVideo.endsWith('.jpeg') || selectedVideo.endsWith('.JPEG') || selectedVideo.endsWith('.txt') ?
                  (<div>
                    <img src={`${selectedVideo}`} alt="biz_library_image" style={{ width: '100%' }} />
                  </div>) :
                  selectedVideo.endsWith('.mp4') || selectedVideo.endsWith('.webm') ||
                    selectedVideo.endsWith('.wmv') || selectedVideo.endsWith('.mov') || selectedVideo.endsWith('.avi') ? (

                    <video controls autoPlay style={{ width: '100%' }}>
                      <source src={`${selectedVideo}`} type="video/mp4" />
                    </video>) : (
                    <div>Unsupported file type: {selectedVideo}</div>
                  )
                }
              </div>
            </div>

          </div>
        </div> : ''}
      {/* end */}
      <div>
      </div>
      <div className="market-place">
        <div style={{ display: 'flex' }}>
          <h1>Connect To Mineral SSO</h1>
          {permissionUser === 'true' ? (
            <div className="mineral-sec" style={{ marginLeft: '10px' }}>
              <button className="btn-mineral" onClick={handleToggle}>
                Configration
              </button>
            </div>
          ) : ''}
        </div>

        <Slider {...settings}>
          {responseData && responseData.custom_urls && responseData.custom_urls.map((item, index) => (
            <div key={index}>
              {item.url.endsWith('.png') || item.url.endsWith('.PNG') || item.url.endsWith('.jpg') ||
                item.url.endsWith('.JPG') || item.url.endsWith('.jpeg') || item.url.endsWith('.JPEG') || item.url.endsWith('.txt') ? (
                <div>
                  <img
                    src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`}
                    alt="biz_library_image"
                    className="mineral_cart_img"
                    onClick={(e) => SelectVideo(e, index)}
                  />
                </div>
              ) : item.url.endsWith('.mp4') || item.url.endsWith('.webm') || item.url.endsWith('.wmv') ||
                item.url.endsWith('.mov') || item.url.endsWith('.avi') ? (
                <div>
                  <video width="100%" controls autoPlay="true" className="video_img" onClick={(e) => { SelectVideo(e) }}>
                    <source src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <div>Unsupported file type: {item.url}</div>
              )}
            </div>
          ))}
        </Slider>
      </div>
      {isToggle && (
        <Modal closeModal={handleToggle} >
          {responseData.length === 0 ? (
            <Loading />
          ) :
            <>
              <div className="career-model">
                <h1 className="text-center">Configuration</h1>
                {Lodding === true ? <Loading /> :
                  <div className="rowdata">
                    <div className="columndata" style={{ height: '100%' }}>
                      {customField.map((item, index) => {
                        return (
                          <div key={index} className="custom-field">
                            <label htmlFor="">Add Url {index + 1}</label>
                            <div className="box" style={{ marginBottom: '10px' }} >
                              <div className="opacity" >
                                <input
                                  type="file"
                                  className="biz_lib_file"
                                  onChange={(e) => handleCustomChange(e, index)}
                                  style={{ width: '100%' }}
                                  accept="image/*,video/*"
                                />
                              </div>
                              <button className="cstm-close-btn"
                                onClick={() => handleDeleteCustomField(index, item)}>
                                Remove
                              </button>
                            </div>
                          </div>
                        );
                      })}
                      <div className="cstm-flex-end">
                        <button type="button" onClick={handleAddCustomField} style={{ marginBottom: '10px' }}>
                          + Add Files
                        </button>
                      </div>
                      <div className="submit-form">
                        <div className="mul-association-btn my-flex my-justify-content-end">
                          <button onClick={handleToggle}>Cancel</button>
                          <button className="ms-2" onClick={SendUrlData} disabled={isSaveDisabled || customField.length === 0}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="cstm-row border-left">
                      {responseData && responseData.custom_urls && responseData.custom_urls.map((item, index) => (
                        <div key={index} className="cstm-col-4" >
                          {item.url.endsWith('.png') || item.url.endsWith('.PNG') || item.url.endsWith('.jpg') ||
                            item.url.endsWith('.JPG') || item.url.endsWith('.jpeg') || item.url.endsWith('.JPEG') || item.url.endsWith('.txt') ? (
                            <div className="PptParent">
                              <div className="RemovePpt">
                                <img src={closeppt} onClick={(e) => RemovedImgAndVideo(e, item)}
                                  alt="biz_library_image" style={{ objectFit: 'cover', width: '18px', height: 'auto' }} />
                              </div>
                              <img
                                src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`}
                                alt="biz_library_image"
                                className="PresentationImage"
                              />
                            </div>
                          ) : item.url.endsWith('.mp4') || item.url.endsWith('.webm') ? (
                            <div className="PptParent">

                              <div className="RemovePpt">
                                <img src={closeppt} onClick={(e) => { RemovedImgAndVideo(e, item) }}
                                  alt="biz_library_image" style={{ objectFit: 'cover', width: '18px', height: 'auto' }} />
                              </div>

                              <video width="100%" controls autoPlay="true" style={{ objectFit: 'cover' }}>
                                <source src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          ) : (
                            <div>Unsupported file type: {item.url}</div>
                          )}
                        </div>
                      ))}
                    </div>

                  </div>
                }

              </div>
            </>
          }
        </Modal>)}
      <Toaster />
    </>
  );
};

const mapStateToProps = (state) => {
  const { userAdminPermission } = state.dashboard
  return { userInfo: state.products.userInfo, productId: state.entities.products.mineral.product_id, permission: userAdminPermission };
};

export default withRouter(connect(mapStateToProps)(MineralBody));