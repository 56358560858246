import React, { useState } from 'react'
import SalesforceModal from '../../dashboard/components/UI/SalesforceModal'
import { getToken } from '../../actions/auth'
import axios from 'axios'
import toast from 'react-hot-toast'
import Loading from '../ui/Loading'

export default function SubmitCase(props) {
    const { setOpenModal } = props
    const [name, setName] = useState('');
    // const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [loader, setLoader] = useState(false)
    const closeModal = () => {
        setOpenModal(false)
    }

    const resetForm = () => {
        setName('')
        // setEmail('')
        setPhone('')
        setSubject('')
        setDescription('')
    }
    const handleSubmit = async (event) => {
        setLoader(true)
        event.preventDefault();
        const requestdata = {
            name: name,
            // email: email,
            phone: phone,
            subject: subject,
            description: description
        }
        try {
            const { token, userToken } = getToken();
            const response = await axios.post(
                "/api/sf/submit-case",
                requestdata,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            console.log('Response:-', response);
            toast.success("Created Successfully !");
            closeModal()
            resetForm()
        } catch (error) {
            console.log('Error:-', error)
        }
        setLoader(false)
    };
    return (
        <div>
            <SalesforceModal
                heading="Submit Case"
                closeModal={closeModal}>
                <div className='saleforse-count'>
                    <form onSubmit={handleSubmit}>
                        <div className='salesforce_modal'>
                            {loader === true ? <Loading /> :
                                <>
                                    <label for="name" style={{ marginTop: '10px' }}>Name
                                        <span style={{ color: 'red' }}>*</span>
                                    </label>
                                    <input
                                        id="name"
                                        type="text"
                                        className='sales_input'
                                        required
                                        value={name}
                                        onChange={(event) => setName((event.target.value).trimStart())}
                                        maxLength="80"
                                        size="20"
                                    />
                                    <div>
                                        {/* <div>
                                            <label for="email">Email</label>
                                            <span style={{ color: 'red' }}>*</span>
                                            <input
                                                id="email"
                                                type="email"
                                                className='sales_input'
                                                required
                                                value={email}
                                                onChange={(event) => setEmail(event.target.value)}
                                                maxLength="80"
                                                size="20"
                                            />
                                        </div> */}
                                        <div>
                                            <label for="phone">Phone</label>
                                            <span style={{ color: 'red' }}>*</span>
                                            <input
                                                id="phone"
                                                type="number"
                                                className='sales_input'
                                                value={phone}
                                                required
                                                onChange={(event) => setPhone(event.target.value)}
                                                maxLength="40"
                                                size="20"
                                            />
                                        </div>
                                    </div>
                                    <label for="subject">Subject</label>
                                    <span style={{ color: 'red' }}>*</span>
                                    <input
                                        id="subject"
                                        type="text"
                                        className='sales_input'
                                        value={subject}
                                        required
                                        onChange={(event) => setSubject((event.target.value).trimStart())}
                                        maxLength="80"
                                        size="20"
                                    />
                                    <label for="description">Description</label>
                                    <textarea
                                        value={description}
                                        className='salestextarea'
                                        onChange={(event) => setDescription(event.target.value)}
                                    />
                                    <div className='saleforse-btn'>
                                        <button type='submit' disabled={(subject && name && phone) === '' ? true : false}>
                                            Submit
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </form>
                </div>
            </SalesforceModal>
        </div>
    )
}
