import React, { useEffect, useState } from "react";
import useFetch from "../../../hooks/useFetch";
import Loading from "../../../../components/ui/Loading";
import blankimg from "../../../../assets/images/images-pro.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ProductCompanyReport from "./ProductCompanyReport";
import Chart from "react-google-charts";
import ActivityReport from "./ActivityReport";
import productIcon from "../../../../assets/images/product-items.png";
import Connecters from "./Connecters";
import ConnecterCompany from "./ConnecterCompany";

function ProductReports() {
  const [fetchProductCompany, ProductLoading] = useFetch();
  const [companyProductData, setCompanyProductData] = useState([]);
  const [openProduct, setOpenProduct] = useState(false);
  const [companyDataCopy, setCompanyDataCopy] = useState([]);
  const [id, setid] = useState("");
  const [productId, setProductId] = useState("");
  const [connecterOpen, setConnecterOpen] = useState("");
  const [openConCompany, setOpenConCompany] = useState(false);
  const [ConCompanyData, setConCompanyData] = useState([]);
  const [filetrData, setFiletrData] = useState([]);
  const [productName, setProductName] = useState("");
  const [connecterCompany, setConnecterCompany] = useState("");
  const [data, setData] = useState([
    ["Task", "Products"],
    ["Mineral", 10],
    ["Bizlibrary", 2],
    ["Webclock", 2],
    ["Cloudpunch", 8],
    ["Telent Management", 8],
    ["Automated Report", 8],
    ["Consolidated Report", 8],
    ["User Provisioning", 8],
  ]);
  const [activityPopup, setActivityPopup] = useState(false);
  const [activityId, setActivityId] = useState("");
  const productCompany = async () => {
    try {
      const payload = {
        endPoint: "/api/product/companies",
        method: "get",
      };
      const res = await fetchProductCompany(payload);
      setCompanyProductData(res);
      setCompanyDataCopy(res);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    productCompany();
  }, []);

  const modalProduct = (id) => {
    setid(id);
    setOpenProduct(true);
  };

  // useEffect(() => {
  //     const filterdata = companyProductData && companyProductData.map(item => item.companies.length);
  //     const productNames = companyProductData && companyProductData.map(item => item.name);
  //     const newData = [["Task", "Products"]];
  //     productNames.forEach((name, index) => {
  //         newData.push([name, filterdata[index]]);
  //     });
  //     setData(newData);
  // }, [companyProductData]);
  useEffect(() => {
    if (Array.isArray(companyProductData)) {
      const filterdata = companyProductData.map(
        (item) => item.companies.length
      );
      const productNames = companyProductData.map((item) => item.name);
      const newData = [["Task", "Products"]];

      productNames.forEach((name, index) => {
        newData.push([name, filterdata[index]]);
      });

      setData(newData);
    } else {
      // Handle the case where companyProductData is not an array
      console.error("companyProductData is not an array:", companyProductData);
      setData([["Task", "Products"]]); // Reset or set default data
    }
  }, [companyProductData]);

  const options = {
    is3D: true,
    title: "Products Activities",
  };

  const ActivityOpen = (id) => {
    setActivityPopup(true);
    setActivityId(id);
  };

  const openConnecters = (id) => {
    setProductId(id.id);
    setProductName(id.name);
    switch (id.id) {
      case 1:
        setConnecterOpen(true);
        break;
      case 2:
        setConnecterOpen(true);
        break;
      default:
        setConnecterOpen(false);
        break;
    }
  };

  return (
    <>
      {openProduct && (
        <ProductCompanyReport
          setOpenProduct={setOpenProduct}
          companyDataCopy={companyDataCopy}
          id={id}
        />
      )}
      {activityPopup && (
        <ActivityReport
          setActivityPopup={setActivityPopup}
          activityId={activityId}
        />
      )}
      {connecterOpen && (
        <Connecters
          productId={productId}
          setOpenConCompany={setOpenConCompany}
          setConCompanyData={setConCompanyData}
          setFiletrData={setFiletrData}
          setConnecterOpen={setConnecterOpen}
          setConnecterCompany={setConnecterCompany}
          productName={productName}
        />
      )}
      {openConCompany && (
        <ConnecterCompany
          setOpenConCompany={setOpenConCompany}
          ConCompanyData={ConCompanyData}
          setConCompanyData={setConCompanyData}
          filetrData={filetrData}
          setFiletrData={setFiletrData}
          productId={productId}
          connecterCompany={connecterCompany}
        />
      )}
      {ProductLoading ? (
        <Loading />
      ) : (
        <div className="cstm-height-main-wrapper" style={{ marginTop: "30px" }}>
          <div className="cstm-row mt-2 total-card">
            <div
              className="cstm-col-3 companyInfoCardCol"
              style={{ width: "27%" }}
            >
              <div className="cstm-card">
                <div className="cstm-row">
                  <div className="cstm-col-6">
                    <div className="cstmflexCenter">
                      <span className="product-item-png">
                        <img src={productIcon} alt="totalComanyIcon" />
                      </span>
                    </div>
                  </div>
                  <div className="cstm-col-6">
                    <h2 className="cstm-card-head">
                      {companyProductData && companyProductData.length}
                    </h2>
                    <span className="cstm-sub-heading">Total Products</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="report-card activity_chart">
              <div className="item-card graph-item">
                <Chart
                  chartType="PieChart"
                  data={data}
                  options={options}
                  loader={<div>Loading Data...</div>}
                  width={"98%"}
                  height="100%"
                />
              </div>
            </div>
          </div>
          <div className="cstm-row mt-2   ">
            <div className="cstm-col-12">
              <div className="cstm-card">
                <div className="cstm-tbl_container">
                  <table>
                    <thead className="tab-head">
                      <tr>
                        <th>S.No</th>
                        <th>Product Name</th>
                        <th>Icon</th>
                        <th>Description</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(companyProductData) &&
                        companyProductData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ textAlign: "center" }}>
                                {index + 1}
                              </td>
                              <td>{item.name}</td>
                              <td className="logo-pro-div">
                                <img
                                  onClick={() => {
                                    openConnecters(item);
                                  }}
                                  title={index <= 1 ? "Connector" : ""}
                                  src={`${
                                    item.image_url ? item.image_url : blankimg
                                  }`}
                                  alt="img"
                                  className={
                                    index <= 1
                                      ? "comp-pro-img"
                                      : "comp-pro-img cursor_point"
                                  }
                                />
                              </td>
                              <td>{item.description}</td>
                              <td>
                                <div>
                                  <button
                                    onClick={() => {
                                      modalProduct(item.id);
                                    }}
                                    className="me-10-px"
                                    title="Companies"
                                  >
                                    <FontAwesomeIcon icon={solid("eye")} />
                                  </button>
                                  <button
                                    onClick={() => {
                                      ActivityOpen(item.id);
                                    }}
                                    className="me-10-px"
                                    title="Activity"
                                  >
                                    <FontAwesomeIcon
                                      icon={solid("clock-rotate-left")}
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductReports;
