import React from "react";
import { connect } from "react-redux";
import {
  disconnectIntegration,
  integrationConnectDisconnect,
} from "../../../../actions/integrations";
import IntegrationDataTable from "./IntegrationDataTable";
import { renderImage } from "../../../../utils/utils";
import { fetchProducts } from "../../../../actions/products";
import {
  showIntegrationConfigurationButton,
  showIntegrationConfigurationUser,
} from "../../../../constants/constant";

const CommonIntegration = (props) => {
  const {
    integrationName,
    dispatch,
    integrationData,
    handleSearch,
    handlePageClick,
    handleRefresh,
    rowData,
    isLoading,
    totalRecord,
    currentPage,
    syncLoading,
    handleConfigurationToggle,
    userAdminPermission,
  } = props;
  const isAdminPerm = localStorage.getItem('isAdmin')
  const isAdminPermision = JSON.parse(isAdminPerm);

  var mineralhost = window.location.hostname;

  const handleConnectDisconnect = async (integrationURL, val) => {
    const body = {
      event_url: integrationURL,
      st: val,
    };
    await dispatch(integrationConnectDisconnect(body));
    dispatch(fetchProducts());
  };
  const disconnectConnector = (integrationURL) => () => {
    switch (integrationURL) {
      case "atera":
        handleConnectDisconnect(integrationURL, 0);
        return;
      case "8x8":
        handleConnectDisconnect(integrationURL, 0);
        return;
      case "biz_library":
        handleConnectDisconnect(integrationURL, 0);
        return;
      default:
        dispatch(disconnectIntegration(integrationName));
        return;
    }
  };

  const launchWindow = (redirectURL, integrationURL) => () => {
    switch (integrationURL) {
      case "atera":
        handleConnectDisconnect(integrationURL, 1);
        return;
      case "8x8":
        handleConnectDisconnect(integrationURL, 1);
        return;
      case "biz_library":
        handleConnectDisconnect(integrationURL, 1);
        return;
      case "mineral":
        window.location.replace(redirectURL);
        return;

      default:
        window.location.replace(redirectURL);
        return;
    }
  };

  if (!integrationData) {
    return null;
  }

  return (
    <>
      <div className="heading">
        <div className="left-heading">
          <h2 className="pb-0">
            {integrationData.integration_name} Integration
          </h2>
          {/* {integrationData.url === "mineral" || integrationData.url === "biz_library" ?  */}
          {
          integrationData.url  ? 
          (
            // userAdminPermission && 
            (
              <>
                <h4 className="m-0">Connection Status</h4>
                {integrationData.authenticated ? (
                  <div className="connected">
                    <div className="good color-green">
                      <strong>
                        Connected to {integrationData.integration_name}
                        {!showIntegrationConfigurationUser.includes(
                          integrationData.url
                        ) && `${integrationData.authed_username}`}
                      </strong>
                    </div>
                    <div className="connect">
                      {isAdminPermision === true ? 
                      <button
                        className="red suite-btn"
                        onClick={disconnectConnector(integrationData.url)}
                      >
                        DISCONNECT THIS USER
                      </button> 
                      : ''}
                    </div>
                  </div>
                ) : (
                  <div>
                    {/* <div className="bad">Not Connected</div> */}
                    <p className="mb-0 mt-0"> Not Connected</p>
                    <div className="suite">
                      <button
                        className="suite-btn"
                        onClick={launchWindow(
                          integrationData.url === "mineral"
                            ? "https://" + mineralhost + "/mineral/login"
                            : integrationData.auth_url,
                          integrationData.url
                        )}
                      >
                        Sign Into {integrationData.integration_name}
                      </button>
                    </div>
                    {/* <div>
                      {showIntegrationConfigurationButton.includes(
                        integrationData.url
                      ) && (
                          <button
                            className="suite-btn mt-1"
                            onClick={handleConfigurationToggle}
                          >
                            Configuration
                          </button>
                        )}
                    </div> */}
                  </div>
                )}
              </>
            )
          ) : (
            <>
              {userAdminPermission ? <h4 className="m-0">Connection Status</h4> : ''}
              {integrationData.authenticated ? (
                <div className="connected">
                  <div className="good color-green">
                    <strong>
                      {userAdminPermission ? <>Connected to {integrationData.integration_name}</> : ''}
                      {!showIntegrationConfigurationUser.includes(
                        integrationData.url
                      ) && `${integrationData.authed_username}`}
                    </strong>
                  </div>
                  <div className="connect">
                    {isAdminPermision === true ?
                     <button
                      className="red suite-btn"
                      onClick={disconnectConnector(integrationData.url)}
                    >
                      DISCONNECT THIS USER
                    </button> 
                    : ''}
                  </div>
                </div>
              ) : (
                <div>
                  {/* <div className="bad">Not Connected</div> */}
                  <p className="mb-0 mt-0"> Not Connected</p>
                  {isAdminPermision === true && integrationData.url === 'biz_library' ? 
                  <div className="suite">
                    <button
                      className="suite-btn"
                      onClick={launchWindow(
                        integrationData.url === "mineral"
                          ? "https://" + mineralhost + ":/mineral/login"
                          : integrationData.auth_url,
                        integrationData.url
                      )}
                    >
                      Sign Into {integrationData.integration_name}
                    </button>
                  </div> 
                  : ''}
                  {/* <div>
                    {showIntegrationConfigurationButton.includes(
                      integrationData.url
                    ) && (
                        <button
                          className="suite-btn mt-1"
                          onClick={handleConfigurationToggle}
                        >
                          Configuration
                        </button>
                      )}
                  </div> */}
                </div>
              )}
            </>
          )
          }
        </div>
        <div className="side-img">
          <img
            src={renderImage(integrationData.image_name)}
            alt={`${integrationData.image_name + "Logo"}`}
          />
        </div>
      </div>
      {/* {integrationData.url === 'biz_library' ? "" : */}
      <IntegrationDataTable
        dispatch={dispatch}
        integrationName={integrationName}
        handleSearch={handleSearch}
        handlePageClick={handlePageClick}
        handleRefresh={handleRefresh}
        rowData={rowData}
        isLoading={isLoading}
        totalRecord={totalRecord}
        currentPage={currentPage}
        syncLoading={syncLoading}
      />
      {/* } */}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { isFetching: eventsAreFetching } = state.events;
  const { events, integrations } = state.entities;

  const topicEvents = events[ownProps.integrationName];

  const { userAdminPermission } = state.dashboard;

  return {
    events: topicEvents,
    eventsAreFetching,
    integrationData: integrations[ownProps.integrationName],
    userAdminPermission,
  };
};

export default connect(mapStateToProps)(CommonIntegration);
