import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import Modal from '../ui/Modal';
import { getToken } from '../../actions/auth';
import axios from 'axios';
import Loading from '../ui/Loading';
import toast from 'react-hot-toast';
import closeppt from '../../assets/images/Crossppt.svg'
import blankBannerImg from '../../assets/images/blankImg.png'

export default function Banner(props) {
    const { ediBanner, setEdiBanner, permissionUser } = props
    const [GetBannerImage, setgetBannerImage] = useState([])
    const [Lodding, setLodding] = useState(false);
    const [refresh, setRefresh] = useState(false)
    const [internalserver, setInternalserver] = useState(false)
    const [customField, setCustomField] = useState([{ custom_url: '' }]);
    const isSaveDisabled = customField.some(item => !item.custom_url);
    const settings = {
        autoplay: true,
        dots: true,
        infinite: true,
        autoplaySpeed: 5000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,

                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true
                }
            },
            {
                breakpoint: 700,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const handleToggle = () => {
        setEdiBanner(!ediBanner)
    };

    useEffect(() => {
        getBannerImg()
    }, [refresh])

    const getBannerImg = async () => {
        setInternalserver(true)
        try {
            const { token, userToken } = getToken();
            const response = await axios.get(
                `/api/product/external_desc/sfd101`,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            setgetBannerImage(response.data)
        } catch (error) {
            console.log("Error:-", error.response.status);
            if (error.response.status === 500 || error.response.status === 502) {
                setInternalserver(true)
            }
        }
        setInternalserver(false)
    }
    const handleAddCustomField = () => {
        setCustomField([...customField, { custom_url: "" }]);
    };

    const handleCustomChange = (e, index) => {
        const { files } = e.target;
        if (files.length > 0) {
            const file = files[0];
            const fileSizeInMB = file.size / (1024 * 1024);

            if (fileSizeInMB > 10) {
                toast.error('File is too large. Please select a file smaller than 10 MB.');
                return;
            }

            const fileExtension = file.name.split('.').pop().toLowerCase();
            const validExtensions = ['jpg', 'jpeg', 'png', 'gif', 'mp4', 'avi', 'mov', 'wmv', 'webm'];

            if (!validExtensions.includes(fileExtension)) {
                toast.error('Invalid file type. Please select an image or video file.');
                return;
            }
            const copy = [...customField];
            copy[index].custom_url = file;
            setCustomField(copy);
        }
    };

    const handleDeleteCustomField = async (index, row) => {
        const copy = [...customField];
        copy.splice(index, 1);
        setCustomField(copy);
    };

    const SendUrlData = async () => {
        try {
            const { token, userToken } = getToken();
            setLodding(true);
            const formData = new FormData();
            formData.append('product_id', 'sfd101');

            customField.forEach(({ custom_url }) => {
                formData.append(`custom_urls`, custom_url);
            });
            await axios.post(
                "/api/product/add/external_desc/urls",
                formData,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            setLodding(false);
            setRefresh(!refresh)
            toast.success("Banner added successfully!")
            setCustomField([{ custom_url: '' }])
            handleToggle(false)
        } catch (error) {
            setLodding(false);
            toast.error("Invalid request error")
        }
    }

    const RemovedImgAndVideo = async (e, row) => {
        try {
            const { token, userToken } = getToken();
            setLodding(true);
            const formData = new FormData();
            formData.append('product_id', 'sfd101');

            formData.append('url', row.url)
            await axios.post(
                "/api/product/remove/external_desc/urls",
                formData,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            setLodding(false);
            toast.success("Removed Successfully!")
            setRefresh(!refresh)
        } catch (error) {
            setLodding(false);
            toast.error("Invalid request error")
        }
    }

    return (
        <>
            {internalserver === true ? <Loading /> : <div className='bannerimage-container'>
                <p className='editbanner-icon'>
                    {permissionUser === true &&
                        <div style={{cursor:'pointer'}}>
                            {ediBanner ? (
                                <div className="topic_close" onClick={handleToggle}>
                                    <FontAwesomeIcon icon={solid("xmark")} />
                                </div>
                            ) : (
                                <span className="pen-banner" onClick={() => handleToggle()}>
                                    <FontAwesomeIcon icon={solid("pen-to-square")} />
                                </span>
                            )}
                        </div>}
                </p>
                {GetBannerImage.length === 0 ? (
                    // 'No banner found'
                    <Loading />
                ) :
                    GetBannerImage && GetBannerImage.custom_urls && GetBannerImage.custom_urls.length === 0 ? (
                        <>
                            {/* <p className='editbanner-msg'>Please add banner !</p> */}
                            <div className="slider-data-not-found">
                                <img
                                    src={blankBannerImg}
                                    alt="biz_library_image"
                                    className="salesforce_silider"
                                />
                            </div>
                        </>
                    ) :
                        <Slider {...settings} className='slider_li'>
                            {GetBannerImage && GetBannerImage.custom_urls && GetBannerImage.custom_urls.map((item, index) => (
                                <div key={index}>
                                    {item.url.endsWith('.png') || item.url.endsWith('.PNG') || item.url.endsWith('.jpg') ||
                                        item.url.endsWith('.JPG') || item.url.endsWith('.jpeg') || item.url.endsWith('.JPEG') || item.url.endsWith('.txt') ? (
                                        <div>
                                            <img
                                                src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`}
                                                alt="biz_library_image"
                                                className="salesforce_silider"
                                            />
                                        </div>
                                    ) : item.url.endsWith('.mp4') || item.url.endsWith('.webm') || item.url.endsWith('.wmv') ||
                                        item.url.endsWith('.mov') || item.url.endsWith('.avi') ? (
                                        <div>
                                            <video width="100%" controls autoPlay="true" className="video_img">
                                                <source src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    ) : (
                                        <div>Unsupported file type: {item.url}</div>
                                    )}
                                </div>
                            ))}
                        </Slider>
                }
            </div>}
            {ediBanner && (
                <Modal closeModal={handleToggle} >
                    {GetBannerImage.length === 0 ? (
                        <Loading />
                    ) :
                        <>
                            <div className="career-model">
                                <h1 className="text-center">Banner</h1>
                                {Lodding === true ? <Loading /> :
                                    <div className="rowdata">
                                        <div className="columndata" style={{ height: '100%' }}>
                                            {customField.map((item, index) => {
                                                return (
                                                    <div key={index} className="custom-field">
                                                        <label htmlFor="">Add Banner {index + 1}  (5200X2000)px</label>
                                                        <div className="box" style={{ marginBottom: '10px' }} >
                                                            <div className="opacity" >
                                                                <input
                                                                    type="file"
                                                                    className="biz_lib_file"
                                                                    onChange={(e) => handleCustomChange(e, index)}
                                                                    style={{ width: '100%' }}
                                                                    accept="image/*,video/*"
                                                                />
                                                            </div>
                                                            <button className="cstm-close-btn"
                                                                onClick={() => handleDeleteCustomField(index, item)}>
                                                                Remove
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            <div className="cstm-flex-end">
                                                <button type="button" onClick={handleAddCustomField} style={{ marginBottom: '10px' }}>
                                                    + Add Files
                                                </button>
                                            </div>
                                            <div className="submit-form">
                                                <div className="mul-association-btn my-flex my-justify-content-end">
                                                    <button onClick={handleToggle}>Cancel</button>
                                                    <button className="ms-2" onClick={SendUrlData} disabled={isSaveDisabled || customField.length === 0}>
                                                        Save
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cstm-row border-left">
                                            {GetBannerImage && GetBannerImage.custom_urls && GetBannerImage.custom_urls.map((item, index) => (
                                                <div key={index} className="cstm-col-4" >
                                                    {item.url.endsWith('.png') || item.url.endsWith('.PNG') || item.url.endsWith('.jpg') ||
                                                        item.url.endsWith('.JPG') || item.url.endsWith('.jpeg') || item.url.endsWith('.JPEG') || item.url.endsWith('.txt') ? (
                                                        <div className="PptParent">
                                                            <div className="RemovePpt">
                                                                <img src={closeppt} onClick={(e) => RemovedImgAndVideo(e, item)}
                                                                    alt="biz_library_image" style={{ objectFit: 'cover', width: '18px', height: 'auto' }} />
                                                            </div>
                                                            <img
                                                                src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`}
                                                                alt="biz_library_image"
                                                                className="PresentationImage"
                                                            />
                                                        </div>
                                                    ) : item.url.endsWith('.mp4') || item.url.endsWith('.webm') ? (
                                                        <div className="PptParent">

                                                            <div className="RemovePpt">
                                                                <img src={closeppt} onClick={(e) => { RemovedImgAndVideo(e, item) }}
                                                                    alt="biz_library_image" style={{ objectFit: 'cover', width: '18px', height: 'auto' }} />
                                                            </div>

                                                            <video width="100%" controls autoPlay="true" style={{ objectFit: 'cover' }}>
                                                                <source src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${item.url}`} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        </div>
                                                    ) : (
                                                        <div>Unsupported file type: {item.url}</div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                }

                            </div>
                        </>
                    }
                </Modal>)}
        </>
    )
}
