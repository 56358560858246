import React, { useEffect, useState } from 'react'
import ProductModal from '../../dashboard/components/UI/ProductModal';
import Loading from '../ui/Loading';

export default function ScheduleMeeting(props) {
    const { setMeetingmodal } = props
    const [loading, setLoading] = useState(true);
    const close = () => {
        setMeetingmodal(false)
    }

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000)
    }, []);

    return (
        <div>
            <ProductModal
                heading="Meeting Calendar"
                closeModal={close}
            >
                <>
                    {loading ? (
                        <div style={{ margin: '220px 0px 220px 0px' }}>
                            <Loading />
                        </div>
                    ) : (
                        <div className="product-company-detail tabmar">
                            <iframe src="https://calendar.google.com/calendar/embed?src=c_64cf62cdc929bbe12d8d42e6d3788e3243722a56c3d50da0f91b66391db925a1%40group.calendar.google.com&ctz=America%2FLos_Angeles&mode=Agenda" style={{ border: "0", width: "100%", height: "800px", frameborder: '0' }} 
                             title="Google Calendar"/>
                        </div>
                    )}
                </>
            </ProductModal>
        </div>
    )
}
