import React, { useEffect } from "react";
import SideBar from "./SideBar";
import Navigation from "./Navigation";
import { useState } from "react";
import Loading from "../../../components/ui/Loading";
import { getToken } from "../../../actions/auth";
import axios from "axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { themecustomization } from "../../../actions/colortheme";

const EmployeeLayout = ({ children, dispatch }) => {
  // const [fetchColorByKey, fetchLoading] = useFetch()
  const [sidebar, setSidebar] = useState(false);
  const [themeValue, setThemeValue] = useState([])
  const sidebarHandle = () => {
    setSidebar((prev) => !prev);
  };

  // color Theme
  useEffect(() => {
    GetColorCode()
  }, [])

  const GetColorCode = async () => {
    localStorage.removeItem('width');
    try {
      const { token } = getToken();
      const response = await axios.get(
        `/api/customize/panel`,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const data = response.data.theme_dimention.product_Card_dimention
      localStorage.setItem('width', data.width)
      localStorage.setItem('height', data.height)
      localStorage.setItem('loadercolor', response.data.theme_loader.loader_color)
      document.documentElement.style.setProperty('--dynamicwidth', data.width)
      if (response) {
        const fontsty = response.data.theme_font_style.product_card_font_style
        document.documentElement.style.setProperty('--fontFamily', response.data.theme_font_style.font_family);
        document.documentElement.style.setProperty('--productcolor', fontsty.font_style_heading.font_color);
        document.documentElement.style.setProperty('--paracolor', fontsty.font_style_paragraph.font_color);
        document.documentElement.style.setProperty('--proheadingfontsize', fontsty.font_style_heading.font_size);
        document.documentElement.style.setProperty('--prohendingfontstyle', fontsty.font_style_heading.font_style);
        document.documentElement.style.setProperty('--paragraphfontstyle', fontsty.font_style_paragraph.font_style);
        document.documentElement.style.setProperty('--paragraphfontsize', fontsty.font_style_paragraph.font_size);
        document.documentElement.style.setProperty('--bgcolor', fontsty.font_style_heading.font__bg_color);
        localStorage.setItem('loader', response.data.theme_loader.loader_type)
        localStorage.setItem('myColor', response.data.theme_color.color);
        localStorage.setItem('Colorbg', response.data.theme_color.bg_color);
        dispatch(themecustomization(response.data))
        setThemeValue(response.data)
      }
    } catch (error) {
      console.log("Error:", error.response.data.error);
      if (error.response.data.error === "Data not found") {
        handleSave()
      }
    }
  }
  //create panel start 

  const handleSave = async (event) => {
    // event.stopPropagation();
    try {
      const { token } = getToken();
      var data = {
        company_id: "82890",
        contents: [
          {
            content_type: 'theme_color',
            content: `{"color":"#29556a","bg_color":"#ffffff"}`
          },
          {
            content_type: 'theme_dimention',
            content: `{"product_Card_dimention":{"height":"120px","width":"130px"},"sidebar_dimention":{"height":"800px","width":"450px"}}`
          },
          {
            content_type: 'theme_loader',
            content: `{"loader_type":"spinner-cub","loader_color":"#29556a"}`
          },
          {
            content_type: 'theme_font_style',
            content: `{"font_family":"Roboto","product_card_font_style":{"font_style_heading":{"font_style":"italic","font_size":"16px","font_color":"#eeeeee","font__bg_color":"#f0f3f4"},
                    "font_style_paragraph":{"font_style":"italic","font_size":"20px","font_color":"#eeeeee"}}}`
          }
        ]
      };
      await axios.post(
        "/api/customize/panel",
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
    } catch (error) {
      console.log("Error:", error);
    }
  }

  //create panel end 
  const storedColor = localStorage.getItem('myColor');
  if (storedColor) {
    document.documentElement.style.setProperty('--my-color', storedColor);
  }
  const ColorBg = localStorage.getItem('Colorbg');
  if (ColorBg) {
    document.documentElement.style.setProperty('--background', ColorBg);
  }

  //color theme end

  return (
    <>
      {storedColor === null ?
        <div style={{ marginTop: '400px' }}>
          <Loading />
        </div> :
        <>
          <Navigation sidebarValue={sidebar} sidebarHandle={sidebarHandle} themeValue={themeValue} />
          <div className={`emp-content ${sidebar && "sidebar"}`}>
            <SideBar />
            <div className="emp-body">
              <div className="emp-body-inner set-layout" >{children}</div>
            </div>
          </div>
        </>
      }
    </>
  );
};
export default withRouter(connect()(EmployeeLayout));
// export default EmployeeLayout;
