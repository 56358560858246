import React, { useEffect, useState } from "react";
import CompanyDetailsModal from "./CompanyDetailsModal";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import ReactPaginate from "react-paginate";
import Modal from "../../../components/ui/Modal";
import { getToken } from "../../../actions/auth";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import EmployeeCountModal from "./EmployeeCountModal";
// import CompanyLogin from "./CompanyLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Swal from "sweetalert2";
import CompanyLogin from "./CompanyLogin";

const CompanyList = () => {
  const [fetchCompany, companyLoading] = useFetch();
  const [companyData, setCompanyData] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [searchValueSN, setSearchValueSN] = useState("");
  const [activeKey, setActiveKey] = useState(null);
  const [isToggle, setIsToggle] = useState(false)
  const [startEndValue, setStartEndValue] = useState('Starts With')
  const [shortCompanyName, setShortCompanyName] = useState('')
  const [empCount, setEmpCount] = useState(false);
  const [tokenId, setTokenId] = useState('')
  const [selectAll, setSelectAll] = useState(false);
  const [loginModal, setLoginModal] = useState(false)
  const [filetrValue, setFiletrValue] = useState([])
  const [loginCompany, setLoginCompany] = useState('')
  const [filetrData, setFiletrData] = useState([]);
  const [checkData, setCheckData] = useState([]);

  const pageCount = Math.ceil(
    Array.isArray(filetrData)
      ? filetrData.length / rowPerPage
      : 0 / rowPerPage
  );

  const offset = currentPage * rowPerPage;
  const rowPerPageValue = [15, 25, 50, 100, 150, 200];

  const handleActiveKey = (key) => () => {
    setActiveKey(key);
  };

  const handleFetchCompany = async () => {
    try {
      const payload = {
        endPoint: "/api/all/companies",
        method: "get",
      };
      const res = await fetchCompany(payload);
      setCompanyData(res);
      // setCompanyDataCopy(res);
      setFiletrData(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const RefreshData = () => {
    handleFetchCompany()
    setSearchValue('');
    setSearchValueSN('')
    window.location.reload()
  }
  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(Number(value));
  };
  ///////search////start/////

  const startwithEndwith = (e) => {
    setSearchValue('');
    setStartEndValue(e.target.value)
  }
  const handleSearchChange = (e) => {
    const { value } = e.target;
    switch (value) {
      case "All":
        setFiletrData(companyData)
        break;
      case "Active":
        setFiletrData(companyData.filter((item) => {
          return item.status === "Active";
        }))
        break;
      case "Inactive":
        setFiletrData(companyData.filter((item) => {
          return item.status === "Inactive";
        }))
        break;
      case "Terminated":
        setFiletrData(companyData.filter((item) => {
          return item.status === "Terminated";
        }))
        break;

      default:
        const filterData = companyData.filter((item) => {
          return item.status;
        });
        setFiletrData(filterData);
        break;
    }
  };

  // const handleSearchChangeStartCN = (e) => {
  //   const { value } = e.target;
  //   setSearchValueSN('')
  //   setSearchValue(value);
  //   if (startEndValue === 'Starts With') {
  //     const filterData = companyDataCopy.filter((item) => {
  //       const companyName = item.name.toLowerCase();
  //       const firstName = companyName.split(" ")[0];
  //       return firstName && firstName.includes(value.toLowerCase());
  //     });
  //     setCompanyData(filterData);
  //   }
  //   else {
  //     const filterData = companyDataCopy.filter((item) => {
  //       const companyName = item.name.toLowerCase();
  //       const lastName = companyName.split(" ").pop();
  //       return lastName && lastName.includes(value.toLowerCase());
  //     });
  //     setCompanyData(filterData);
  //   }
  // };

  const handleSearchChangeStartCN = (e) => {
    const { value } = e.target;
    setSearchValueSN('');
    setSearchValue(value);
    if (value.trim() === '') {
      setFiletrData(companyData);
    }
    else {
      const filteredData = startEndValue === 'Starts With'
        ? companyData.filter(item => item.name.toLowerCase().startsWith(value.toLowerCase()))
        : companyData.filter(item => item.name.toLowerCase().endsWith(value.toLowerCase()));

      const updatedData = filteredData.map(item => ({
        ...item,
        checked: filetrValue.includes(item.ID),
      }));

      setFiletrData(updatedData);
    }
  }

  // const handleSearchChangeStartSNs = (e) => {
  //   const { value } = e.target;
  //   setSearchValue('');
  //   setSearchValueSN(value)
  //   const filterData = companyDataCopy.filter((item) => {
  //     return item.short_name.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setCompanyData(filterData);
  // };

  const handleSearchChangeStartSN = (e) => {
    const { value } = e.target;
    setSearchValue('');
    setSearchValueSN(value);
    if (value.trim() === '') {
      setFiletrData(companyData);
    } else {
      const filtered = companyData && companyData.filter((item) =>
        item.short_name.toLowerCase().includes(value.toLowerCase())
      );
      setFiletrData(filtered);
    }
    setCurrentPage(0);
  };
  // const dataForTable = filetrData && filetrData.slice(offset, offset + rowPerPage);
  const dataForTable = Array.isArray(filetrData) ? filetrData.slice(offset, offset + rowPerPage) : [];
  /////search////end////
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  useEffect(() => {
    if (companyData.length === 0) {
      handleFetchCompany();
    }
  }, []);

  // console.log('companyData',companyData)

  // Export Data start // 
  const exportToCSV = () => {
    const headers = ['Company Short Name', 'Company Name', 'Total Employees', 'Status'];
    const AllfilterValue = checkData.length > 0 ? checkData : companyData
    const csvData = AllfilterValue.map(item => {
      const companyName = item.name.replace(/,/g, '');
      return [
        item.short_name,
        companyName,
        item.total_emp,
        item.status,
      ].join(',');
    });
    const csvContent = [
      headers.join(','),
      ...csvData
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Company_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  //update company profile

  const infoModal = (e) => {
    setShortCompanyName(e)
    setIsToggle(!isToggle);
  }
  const EmpCountData = (e) => {
    setTokenId(e)
    setEmpCount(true)
  }

  const updateCompanyName = async () => {
    try {
      const { token, userToken } = getToken();
      var formData = {
        company_id: shortCompanyName && shortCompanyName.ID,
        name: shortCompanyName && shortCompanyName.name
      }
      await axios.post(
        "/api/update/company/profile",
        formData,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      toast.success("Updated Successfully!")
      setSearchValueSN('')
      setIsToggle(false)
      setSearchValue('');
    } catch (error) {
      toast.error("Invalid request error")
      console.log("Error:", error);
    }
    handleFetchCompany()

  }
  const handleChange = (e) => {
    setShortCompanyName({ ...shortCompanyName, [e.target.name]: (e.target.value).trimStart() })
  }
  // Chech box functionality start
  const AllCheckbox = (e) => {
    const isChecked = e.target.checked;
    const updatedData = filetrData.map((item) => ({ ...item, checked: isChecked }));
    setFiletrData(updatedData);
    setSelectAll(isChecked);

    if (isChecked) {
      setFiletrValue(updatedData);
    } else {
      setFiletrValue([]);
    }
    //new
    // if (isChecked) {
    //   setFiletrValue(updatedData.map(item => item)); // Store all IDs in filterValue
    // } else {
    //   setFiletrValue([]); // Reset filterValues when all are unchecked
    // }

  };

  const CheckboxChange = (id) => {
    const updatedData = filetrData.map(item => ({ ...item, checked: item.ID === id.ID ? !item.checked : item.checked }));
    setFiletrData(updatedData)

    const allChecked = updatedData.every(item => item.checked);
    setSelectAll(allChecked);

    const filterData = updatedData.filter(item => item.checked)
    setFiletrValue(filterData)

    const updatedDatad = companyData.map(item => ({ ...item, checked: item.ID === id.ID ? !item.checked : item.checked }));
    setCompanyData(updatedDatad)

    // const allChecked = updatedData.every(item => item.checked);
    // setSelectAll(allChecked);

    const filterDatad = updatedDatad.filter(item => item.checked)
    // setFiletrValue(filterData)
    setCheckData(filterDatad)
  };


  //login company
  const OpenLoginModal = async (event) => {
    setLoginCompany(event);
    setLoginModal(!loginModal)
  }
  //delete company//

  const deleteCompany = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure you want to remove it?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
    if (result.isConfirmed) {
      try {
        const { token, userToken } = getToken();
        var data = {
          company_id: id,
          status: 0
        }
        await axios.post(
          "/api/deactivate/company",
          data,
          {
            headers: {
              Opusertoken: userToken,
              Authorization: token,
            },
          }
        );
        toast.success("Removed!", "Your Company has been Removed.", "successfully")
        handleFetchCompany();
      } catch (error) {
        toast.error("Invalid")
      }
    }
  };

  const restoreCompany = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure you want to restore it?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    });
    if (result.isConfirmed) {
      try {
        const { token, userToken } = getToken();
        var data = {
          company_id: id,
          status: 1
        }
        await axios.post(
          "/api/deactivate/company",
          data,
          {
            headers: {
              Opusertoken: userToken,
              Authorization: token,
            },
          }
        );
        toast.success("Restored!", "Your Company has been Restored.", "successfully")
        handleFetchCompany();
      } catch (error) {
        toast.error("Invalid")
      }
    }
  };
  //
  return (
    <div>
      {activeKey && (
        <CompanyDetailsModal
          activeKey={activeKey}
          handleActiveKey={handleActiveKey}
        />
      )}
      {empCount && (
        <EmployeeCountModal
          setEmpCount={setEmpCount}
          tokenId={tokenId}
        />
      )}
      <div className="company_system_table company-list" >
        <div className="tbl_header">
          <div className="page_upper flex-filter">
            <div className="left_tsec flex-filter">
              <div className="row_filter">
                <label htmlFor="rows">
                  <b>Rows On Page:</b>
                </label>
                <select
                  id="rows"
                  onChange={handleSelectSort}
                  defaultValue={rowPerPage}
                >
                  {rowPerPageValue.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="show_filter flex-filter">
                <div>
                  <label htmlFor="rows">
                    <b>Showing:</b>
                  </label>
                  <span style={{ marginLeft: '5px' }}>
                    {offset}-{offset + rowPerPage} of  {Array.isArray(companyData) && companyData.length}
                  </span>
                </div>
              </div>
            </div>
            <div className="right_tsec flex-filter">
              <div className="mt-2 mb-2 ml-2">
                <button className="export-csv" style={{ marginRight: '5px' }} onClick={RefreshData}>Refresh</button>
                <button className="export-csv" onClick={exportToCSV}>Export to CSV</button>
              </div>
            </div>
          </div>
        </div>
        {companyLoading ? (
          <Loading />
        ) : (
          <div className="tbl_container">
            <table>
              <thead style={{ position: 'sticky', top: '0px', zIndex: 1 }}>
                <tr>
                  <th>
                    <input checked={selectAll}
                      onChange={AllCheckbox} type="checkbox" />
                  </th>
                  <th>Action</th>
                  <th>Company:ShortName</th>
                  <th>Company:Name</th>
                  <th>Total Employee</th>
                  <th>Status</th>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div >
                      <input type="text" placeholder="Search"
                        value={searchValueSN} className="endStart"
                        onChange={handleSearchChangeStartSN} />
                    </div>
                  </td>
                  <td>
                    <div>
                      <select id="company_name" onChange={startwithEndwith}>
                        <option value="Starts With">Starts With</option>
                        <option value="End With">End With</option>
                      </select>
                    </div>
                    <div >
                      <input type="text" placeholder="Search"
                        value={searchValue} className="endStart"
                        onChange={handleSearchChangeStartCN} />
                    </div>
                  </td>
                  <td></td>
                  <td>
                    <div>
                      <select id="company_type" onChange={handleSearchChange}>
                        <option value="All">All</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                        <option value="Terminated">Terminated</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(dataForTable) &&
                  dataForTable
                    // .slice(offset, offset + rowPerPage)
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">
                            <span>
                              <input type="checkbox" checked={item.checked || false}
                                // onChange={() => CheckboxChange(item.ID)}
                                onChange={() => CheckboxChange(item)}
                              />
                            </span>
                          </td>
                          <td className="text-center">
                            <span className="cursor-pointer iconspace">
                              <button
                                className="me-10-px"
                                title="Login">
                                <FontAwesomeIcon className="company-login" icon={solid("key")}
                                  onClick={() => { OpenLoginModal(item.short_name) }}
                                />
                              </button>
                              <button
                                className="me-10-px"
                                title="Product">
                                <img onClick={handleActiveKey(item.token || item.key)}
                                  src="/images/logo/computer.svg"
                                  alt="computer"
                                />
                              </button>
                              <button
                                className="me-10-px"
                                title="Profile">
                                <img onClick={() => { infoModal(item) }}
                                  src="/images/logo/information.svg"
                                  alt="information"
                                />
                              </button>
                              {item.is_active === '1' ? <button
                                className="me-10-px"
                                title="Delete">
                                <FontAwesomeIcon className="company-delete" icon={solid("trash")}
                                  onClick={() => { deleteCompany(item.ID) }} />
                              </button> :
                                <button
                                  className="me-10-px"
                                  title="Restore">
                                  <FontAwesomeIcon className="company-restore" icon={solid("trash-can-arrow-up")}
                                  onClick={() => { restoreCompany(item.ID) }}
                                  />
                                </button>}
                            </span>
                          </td>
                          <td className="inf_td">
                            {item.short_name}
                          </td>
                          <td>{item.name}</td>
                          <td style={{ cursor: 'pointer' }} onClick={() => { EmpCountData(item.ID) }}>{item.total_emp}</td>
                          <td>{item.status}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={pageCount}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              containerClassName={"pagination-container"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
            />
            {isToggle && (
              <Modal closeModal={infoModal} >
                <>
                  <div className="career-model">
                    <h1 className="text-center">Company Profile</h1>
                    <div className="rowdata">
                      <div className="imp-container-report">
                        <div className="my-flex ">
                          <div className="one-col-4 my-flex my-align-item-center mb-2">
                            <strong>
                              Company Short Name <span className="color-red"></span>
                            </strong>
                          </div>
                          <div className="one-col-2 my-flex-grow-1 mb-2">
                            <div className="my-flex">
                              <div className="one-col-12">
                                <div className="one-control-input">
                                  <input
                                    style={{ height: '50px', width: '100%' }}
                                    type="text"
                                    value={shortCompanyName.short_name}
                                    onChange={handleChange}
                                    placeholder="Company Short Name"
                                    name="CompanyShortName"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="my-flex ">
                          <div className="one-col-4 my-flex my-align-item-center mb-2">
                            <strong>
                              Company Name <span className="color-red"></span>
                            </strong>
                          </div>
                          <div className="one-col-2 my-flex-grow-1 mb-2">
                            <div className="my-flex">
                              <div className="one-col-12">
                                <div className="one-control-input">
                                  <input
                                    type="text"
                                    value={shortCompanyName.name}
                                    onChange={handleChange}
                                    style={{ height: '50px', width: '100%' }}
                                    placeholder="Company Name"
                                    name="name"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="my-flex my-justify-content-end">
                          <button
                            className="one-control-btn" style={{ width: '20%', marginRight: '5px' }}
                            onClick={updateCompanyName}
                            disabled={!shortCompanyName.name}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Modal>)}
          </div>
        )}
      </div>
      {loginModal && <CompanyLogin
        OpenLoginModal={OpenLoginModal}
        loginCompany={loginCompany}
      />}
      <Toaster />
    </div>
  );
};

export default withRouter(CompanyList);