import React, { useEffect, useState } from "react";
import "../../styles/DashboardStyle.css";
import DashboardLayout from "../../dashboard/components/layout/DashboardLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useFetch from "../../dashboard/hooks/useFetch";
import Loading from "../ui/Loading";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { getToken } from "../../actions/auth";
import toast, { Toaster } from "react-hot-toast";
import ScheduleMeeting from "./ScheduleMeeting";
import SubmitCaseList from "./SubmitCaseList";
import Banner from "./Banner";
import SubmitCase from "./SubmitCase";
import Announcement from "./Announcement";
import blankImg from "../../assets/images/blank.png";
import { fetchProducts } from "../../actions/products";
import { connect } from "react-redux";
import {
  productDataFetch,
  userPermissionAPICount,
  userWritePermission,
} from "../../actions/dashboard";

function DashBoards(props) {
  const [salesforceData, loading] = useFetch();
  const [getData, setGetData] = useState("");
  const [announcement, setAnnouncement] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [imageSrc, setImageSrc] = useState("");
  const [loader, setLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [ediBanner, setEdiBanner] = useState(false);
  const [hotEditing, setHotEditing] = useState(false);
  const [formsData, setFormsData] = useState([]);
  const [hotTopicData, setHotTopicData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [formloading, setFormloading] = useState(false);
  const [hotloading, setHotloading] = useState(false);
  const [meetingmodal, setMeetingmodal] = useState(false);
  const [permissionUser, setPermissionUser] = useState("");
  const [announcementEdit, setannouncementEdit] = useState(false);
  const [submitCaseList, setsubmitCaseList] = useState(false);
  const [inputForms, setInputForms] = useState(
    Array.isArray(formsData) ? [...formsData] : [{ url: "" }]
  );
  const [inputFields, setInputFields] = useState(
    Array.isArray(hotTopicData) ? [...hotTopicData] : [{ topics: "" }]
  );
  const isSaveDisabled = inputForms.some((item) => !item.url);
  const topicSaveDisabled = inputFields.some((item) => !item.topics);
  const { dispatch, userAdminPermission, productData, userPermissionCount } =
    props;
  useEffect(() => {
    GetDashboardVal();
    GetAnnouncement();
  }, [refresh]);

  const GetDashboardVal = async () => {
    try {
      const payload = {
        endPoint: `/api/sf/user-info`,
        method: "get",
      };
      const response = await salesforceData(payload);
      setGetData(response.data);
      if (response.data && response.data.MediumPhotoUrl) {
        setImageUrl(response.data.MediumPhotoUrl);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    const fetchImage = async () => {
      setLoader(true);
      try {
        const response = await fetch(
          `/api/sf/user-image?url=${encodeURIComponent(imageUrl)}`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch image");
        }
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageSrc(imageObjectURL);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
      setLoader(false);
    };
    fetchImage();
  }, [imageUrl]);

  //
  const GetAnnouncement = async () => {
    try {
      const payload = {
        endPoint: `/api/sf/user-dashboard-config`,
        method: "get",
      };
      const response = await salesforceData(payload);
      setAnnouncement(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const userAdminPermission = localStorage.getItem("userAdminPermission");
    setPermissionUser(userAdminPermission);
  }, [permissionUser]);

  const topicsData =
    announcement &&
    announcement.length > 0 &&
    announcement.find((item) => item.item_name === "hot_topics");

  const openSubmitcase = () => {
    setOpenModal(true);
  };

  //froms
  const formsDatas =
    announcement && announcement.find((item) => item.item_name === "forms");

  useEffect(() => {
    if (formsDatas) {
      setFormsData(formsDatas.config);
      setInputForms(formsDatas.config);
    }
    if (formsDatas === undefined || formsDatas.config === null) {
      setInputForms([{ url: "" }]);
    }
  }, [formsDatas, refresh]);

  const handleEditAble = (index) => {
    setEditing(true);
    setInputForms(
      inputForms.map((field, i) =>
        i === index ? { ...field, editing: true } : field
      )
    );
  };

  const handleChangeForms = (e, index) => {
    const newInputForms = [...inputForms];
    newInputForms[index].url = e.target.value.trimStart();
    setInputForms(newInputForms);
  };

  const plusForms = () => {
    const newInputForms = [...inputForms];
    newInputForms.push({ url: "", editing: false });
    setInputForms(newInputForms);
  };

  const MinusForms = (index) => {
    const newInputForms = [...inputForms];
    newInputForms.splice(index, 1);
    setInputForms(newInputForms);
  };

  const SubmitForms = async () => {
    const formsJson = JSON.stringify(inputForms);
    setFormloading(true);
    try {
      const { token, userToken } = getToken();
      const requestBody = {
        item_name: "forms",
        config: formsJson,
      };

      const response = await axios.post(
        "/api/sf/user-dashboard-config",
        requestBody,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      console.log("Response:-", response);
      toast.success("Created Successfully !");
      setEditing(false);
      setRefresh(!refresh);
    } catch (error) {
      console.log("Error:-", error);
    }
    setFormloading(false);
  };

  // hot topics

  useEffect(() => {
    if (topicsData) {
      setHotTopicData(topicsData.config);
      setInputFields(topicsData.config);
    }
    if (topicsData === undefined || topicsData.config === null) {
      setInputFields([{ topics: "" }]);
    }
  }, [topicsData, refresh]);

  const handleHotEdit = (index) => {
    setHotEditing(true);
    setInputFields(
      inputFields.map((field, i) =>
        i === index ? { ...field, editing: true } : field
      )
    );
  };

  const handlePlusClick = () => {
    setInputFields([...inputFields, { topics: "" }]);
  };

  const handleMinusClick = (index) => {
    setInputFields(inputFields.filter((field, i) => i !== index));
  };

  const handleInputChange = (e, index) => {
    const newFields = [...inputFields];
    newFields[index].topics = e.target.value.trimStart();
    setInputFields(newFields);
  };

  const SubmitHotTopics = async () => {
    const topicsJson = JSON.stringify(inputFields);
    setHotloading(true);
    try {
      const { token, userToken } = getToken();
      const requestBody = {
        item_name: "hot_topics",
        config: topicsJson,
      };

      const response = await axios.post(
        "/api/sf/user-dashboard-config",
        requestBody,
        {
          headers: {
            Opusertoken: userToken,
            Authorization: token,
          },
        }
      );
      console.log("Response:-", response);
      setHotEditing(false);
      setRefresh(!refresh);
      toast.success("Created Successfully !");
    } catch (error) {
      console.log("Error:-", error);
    }
    setHotloading(false);
  };

  const closehotTopic = () => {
    setHotEditing(false);
  };
  const closeForms = () => {
    setEditing(false);
  };

  // schedule meeting

  const ScheduleMeetingbtn = () => {
    setMeetingmodal(true);
  };

  // submit case list

  const submitcaselistopen = () => {
    setsubmitCaseList(true);
  };
  //dashboard api
  useEffect(() => {
    dispatch(fetchProducts())
      .then((res) => {
        console.log("ressss", res);
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }, []);

  const getPermissionWriteUser = async () => {
    dispatch(userPermissionAPICount());
    if (userPermissionCount === 0) {
      const { response } = await dispatch(userWritePermission());
      if (response && response.IsWrite) {
        localStorage.setItem("IsWrite", response.IsWrite);
      }
    }
  };

  const fetchAPIs = async () => {
    if (productData.length === 0) {
      dispatch(productDataFetch());
    }
  };

  useEffect(() => {
    fetchAPIs();
    getPermissionWriteUser();
  }, []);
  localStorage.setItem("userAdminPermission", userAdminPermission);
  ///
  return (
    <DashboardLayout>
      {loading ? (
        <Loading />
      ) : (
        <div className="dash-outer">
          <div>
            <Banner
              ediBanner={ediBanner}
              setEdiBanner={setEdiBanner}
              permissionUser={userAdminPermission}
            />
          </div>
          <div className="dash-inner">
            <div className="dash-card">
              <h3>{getData && getData.CompanyName} Welcome!</h3>
              <p>Service Status/Alerts:</p>
              <button>Active</button>
            </div>
            <div className="servicerap-outer">
              <div className="servicerap-outer-card">
                <h3>Details</h3>
                <p>Photo and contact information on clients service rep.</p>
                <h4>OnePoint info: {getData && getData.Name}</h4>
                <div className="servicerap-inner">
                  <label>Phone:</label>
                  <span>{getData && getData.Phone}</span>
                </div>
                <div className="servicerap-inner">
                  <label>Address:</label>
                </div>
              </div>
              {loader === true ? (
                <div className="dash_image_sales">Loading... </div>
              ) : (
                <div className="image_style">
                  {imageSrc === "" ? (
                    <img className="images_dash" src={blankImg} alt=""></img>
                  ) : (
                    <img className="images_dash" src={imageSrc} alt=""></img>
                  )}
                </div>
              )}
            </div>
            <div className="othercontact-outer">
              <h3>Other Contact</h3>
              <p>Meeting and information on other user</p>
              <div className="othercontact-btn">
                {userAdminPermission === true ? (
                  <button onClick={ScheduleMeetingbtn}>
                    <span className="me-1">
                      <FontAwesomeIcon icon={solid("calendar-days")} />
                    </span>
                    Schedule Meeting
                  </button>
                ) : (
                  <button style={{ cursor: "not-allowed" }}>
                    <span className="me-1">
                      <FontAwesomeIcon icon={solid("calendar-days")} />
                    </span>
                    Schedule Meeting
                  </button>
                )}
                {userAdminPermission === true ? (
                  <button onClick={openSubmitcase}>
                    <span className="me-1 btn-other">
                      <FontAwesomeIcon icon={solid("check-to-slot")} />
                    </span>
                    Submit Case
                  </button>
                ) : (
                  <button style={{ cursor: "not-allowed" }}>
                    <span className="me-1 btn-other">
                      <FontAwesomeIcon icon={solid("check-to-slot")} />
                    </span>
                    Submit Case
                  </button>
                )}
                <button onClick={submitcaselistopen}>
                  <span className="me-1 btn-others">
                    <FontAwesomeIcon icon={solid("eye")} />
                  </span>
                  View Case
                </button>
              </div>
            </div>
          </div>
          <div className="projecttimeline-outer">
            <div className="announcement-right">
              <div className="head_dash">
                <p>Forms</p>
                {userAdminPermission === true && (
                  <button className="announcement-right_form">
                    {editing ? (
                      <div className="topic_close" onClick={closeForms}>
                        <FontAwesomeIcon icon={solid("xmark")} />
                      </div>
                    ) : (
                      <span
                        className="edit-icon-btn"
                        onClick={() => handleEditAble(0)}
                      >
                        <FontAwesomeIcon icon={solid("pen-to-square")} />
                      </span>
                    )}
                  </button>
                )}
              </div>
              {formloading ? (
                <div className="form_load">Loading... </div>
              ) : (
                <>
                  {!editing ? (
                    <>
                      {formsData &&
                        formsData.length > 0 &&
                        formsData.map((val, i) => {
                          return (
                            <div key={i} style={{ marginBottom: "10px" }}>
                              <Link
                                onClick={() =>
                                  window.open(
                                    `https://${val.url}`,
                                    "_blank",
                                    "noopener"
                                  )
                                }
                              >
                                {val.url}
                              </Link>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <div className="hot_field">
                      {inputForms.map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {editing ? (
                            <input
                              type="text"
                              className="sale_form"
                              value={field.url}
                              onChange={(e) => handleChangeForms(e, index)}
                              placeholder="Url"
                            />
                          ) : (
                            <span>{field.url}</span>
                          )}
                          {editing ? (
                            <FontAwesomeIcon
                              icon={solid("xmark")}
                              className="save_btn"
                              onClick={() => MinusForms(index)}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                      <div className="hot-fieldbtn">
                        <button type="button" onClick={plusForms}>
                          Add Field
                        </button>
                        <button
                          onClick={SubmitForms}
                          disabled={isSaveDisabled || inputForms.length === 0}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="hot_topics">
              <div className="hot_topic_heading">
                <p style={{ marginLeft: "15px", color: "white" }}>Hot Topics</p>
                {userAdminPermission === true && (
                  <>
                    {hotEditing ? (
                      <div
                        className="topic_close"
                        style={{ marginRight: "5px" }}
                        onClick={closehotTopic}
                      >
                        <FontAwesomeIcon icon={solid("xmark")} />
                      </div>
                    ) : (
                      <span
                        className="edit-icon-btn"
                        style={{ marginTop: "8px" }}
                        onClick={() => handleHotEdit(0)}
                      >
                        <FontAwesomeIcon icon={solid("pen-to-square")} />
                      </span>
                    )}
                  </>
                )}
              </div>
              {hotloading ? (
                <div className="form_load">Loading... </div>
              ) : (
                <>
                  {!hotEditing ? (
                    <div className="topic_hot">
                      <ul>
                        {topicsData &&
                          topicsData.config.map((val, i) => {
                            return (
                              <li key={i}>
                                <p>
                                  <span className="arrow-rightWrapper">
                                    <FontAwesomeIcon
                                      icon={solid("arrow-right")}
                                      className="arrow-color"
                                    />
                                  </span>
                                  {val.topics}
                                </p>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  ) : (
                    <div className="hot_fields">
                      {inputFields.map((field, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <input
                            type="text"
                            className="hot_input_field"
                            value={field.topics}
                            onChange={(e) => handleInputChange(e, index)}
                            placeholder="Enter hot topics"
                          />
                          <FontAwesomeIcon
                            icon={solid("xmark")}
                            className="save_btn"
                            onClick={() => handleMinusClick(index)}
                          />
                        </div>
                      ))}
                      <div className="hot-fieldbtn">
                        <button type="button" onClick={handlePlusClick}>
                          Add Field
                        </button>
                        <button
                          onClick={SubmitHotTopics}
                          disabled={
                            topicSaveDisabled || inputFields.length === 0
                          }
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="cal-anc">
            <div className="announcement-left-box cal-box">
              <div className="cal-head-anc">
                <p style={{ marginLeft: "15px" }}>Calendar</p>
              </div>
              <iframe
                src="https://calendar.google.com/calendar/embed?src=c_64cf62cdc929bbe12d8d42e6d3788e3243722a56c3d50da0f91b66391db925a1%40group.calendar.google.com&ctz=America%2FLos_Angeles&mode=Agenda"
                style={{
                  border: "0",
                  width: "100%",
                  height: "400px",
                  frameborder: "0",
                }}
                title="Google Calendar"
              />
            </div>
            <div className="announcement-left announcement-left-box announce_box">
              <Announcement
                announcementEdit={announcementEdit}
                setannouncementEdit={setannouncementEdit}
                permissionUser={userAdminPermission}
                announcement={announcement}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            </div>
          </div>
          <div></div>
          {openModal && <SubmitCase setOpenModal={setOpenModal} />}
          {meetingmodal && (
            <ScheduleMeeting setMeetingmodal={setMeetingmodal} />
          )}
          {submitCaseList && (
            <SubmitCaseList setsubmitCaseList={setsubmitCaseList} />
          )}
        </div>
      )}
      <Toaster />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => {
  // const { isAuthenticated } = state.auth;
  const { userAdminPermission, productData, userPermissionCount } =
    state.dashboard;

  return {
    // isAuthenticated,
    userAdminPermission,
    productData,
    userPermissionCount,
  };
};
// export default DashBoards
export default withRouter(connect(mapStateToProps)(DashBoards));
