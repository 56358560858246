import React, { useEffect, useState } from 'react';
import ProductModal from '../../UI/ProductModal';
import ReactPaginate from 'react-paginate';

function ProductCompanyReport(props) {
    const { setOpenProduct, companyDataCopy, id } = props;

    const [rowPerPage, setRowPerPage] = useState(15);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [comapniesData, setComapniesData] = useState([]);
    const [filetrData, setFiletrData] = useState([]);

    const handleClose = () => setOpenProduct(false);

    const offset = currentPage * rowPerPage;
    const rowPerPageOptions = [15, 25, 50, 100];
    const pageCount = Math.ceil(
        filetrData.length / rowPerPage
    );

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    const handleSelectSort = (e) => {
        setRowPerPage(Number(e.target.value));
        setCurrentPage(0);
    };

    const exportToCSV = () => {
        const headers = ['Name', 'Short Name', 'Status'];
        const dataToExport = filetrData.length > 0 ? filetrData : dataForTable;
        const csvData = dataToExport.map((item) => {
            const shortName = item.short_name.replace(/,/g, '');
            const name = item.name.replace(/,/g, '');
            const status = item.is_active === 1 ? 'Active' : 'Disabled';
            return [name, shortName, status].join(',');
        });
        const csvContent = [headers.join(','), ...csvData].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Product_Company.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const handleSearchProduct = (e) => {
        const { value } = e.target;
        setSearchValue(value);
        if (value.trim() === '') {
            setFiletrData(comapniesData);
        } else {
            const filtered = comapniesData && comapniesData.filter((item) =>
                item.name.toLowerCase().includes(value.toLowerCase()) ||
                item.short_name.toLowerCase().includes(value.toLowerCase())
            );
            setFiletrData(filtered);
        }
        setCurrentPage(0);
    };

    useEffect(() => {
        const company = companyDataCopy && companyDataCopy.find((company) => company.id === id);
        if (company) {
            setComapniesData(company.companies);
            setFiletrData(company.companies);
        }
    }, [companyDataCopy, id]);

    const dataForTable = filetrData && filetrData.slice(offset, offset + rowPerPage);

    return (
        <div>
            <ProductModal heading="Product Company" closeModal={handleClose}>
                <div className="company_system_table tabmars">
                    <div className="tbl_header tbl_header-bom">
                        <div className="page_upper flex-filter">
                            <div className="left_tsec flex-filter">
                                <div className="row_filter">
                                    <label htmlFor="rows">
                                        <b>Rows Per Page:</b>
                                    </label>
                                    <select
                                        id="rows"
                                        onChange={handleSelectSort}
                                        value={rowPerPage}
                                    >
                                        {rowPerPageOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="show_filter flex-filter">
                                    <div>
                                        <label>
                                            <b>Showing:</b>
                                        </label>
                                        <span style={{ marginLeft: '5px' }}>
                                            {offset + 1}-
                                            {Math.min(offset + rowPerPage, filetrData.length)} of {filetrData.length}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="right_tsec flex-filter">
                                <div style={{ display: 'flex' }}>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchValue}
                                        className="endStart"
                                        onChange={handleSearchProduct}
                                    />
                                    <button className="export-csv" onClick={exportToCSV} style={{ marginRight: '0px' }}>
                                        Export to CSV
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tbl_container">
                        <table>
                            <thead style={{ position: 'sticky', top: 0 }}>
                                <tr>
                                    <th className="tbl-td-th">Name</th>
                                    <th className="tbl-td-th">Short Name</th>
                                    <th className="tbl-td-th">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataForTable && dataForTable.map((item, index) => {
                                        const status = item.is_active === 1 ? 'Active' : 'Disabled';
                                        return (
                                            <tr key={index}>
                                                <td className="tbl-td-th">{item.name}</td>
                                                <td className="tbl-td-th">{item.short_name}</td>
                                                <td className="tbl-td-th">{status}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {dataForTable.length === 0 ?
                            <div className='pro-tab'>No Data found!</div> : ""}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            previousLabel="Previous"
                            containerClassName="pagination-container"
                            previousLinkClassName="pagination__link"
                            nextLinkClassName="pagination__link"
                            disabledClassName="disabled"
                            activeClassName="active"
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                        />
                    </div>
                </div>
            </ProductModal>
        </div>
    );
}

export default ProductCompanyReport;
