import React, { useState } from 'react'
import ProductModal from '../../dashboard/components/UI/ProductModal'
import MarketPlaceList from './MarketPlaceList'

export default function MarketProductList(props) {
    const { setMarketModal, marketPlaceSlide } = props
    const [marketDetails, setMarketDetails] = useState(false)
    const [itemDetails, setItemDetails] = useState('')
    const close = () => {
        setMarketModal(false)
    }
    const closeMarket = () => {
        setMarketDetails(false)
    }
    const handleCardClick = (item) => {
        setItemDetails(item);
        setMarketDetails(true)
    };

    return (
        <>
            <div>
                <ProductModal
                    heading="Market Product List"
                    closeModal={close}
                >
                    <div className='market-list grid-marketlist'>
                        {marketPlaceSlide &&
                            Array.isArray(marketPlaceSlide.integrations) &&
                            marketPlaceSlide.integrations.map((item) => (
                                <div onClick={() => handleCardClick(item)}>
                                    <MarketPlaceList {...item} />
                                </div>
                            ))}
                    </div>
                </ProductModal>
            </div>
            <div>
                {marketDetails && <ProductModal
                    heading="Market Product Details"
                    closeModal={closeMarket}
                >
                    <div className="provision-modal">
                        <div className="product-modal-header">
                            <span onClick={closeMarket} className="back-btn">
                                ← Back to list of Integrations
                            </span>
                        </div>

                        <div className="product_card_content">
                            <div className={`product-left-content  integration-active`}>
                                <div className="my-flex landingpage-userpro">
                                    <div className="one-col-9">
                                        <h1 className="pb-0 market_name">
                                            {itemDetails.integration_name}
                                        </h1>
                                        <p>{itemDetails.description}</p>
                                    </div>
                                    <div className="side-img" style={{ width: '20%' }}>
                                        <img
                                            src={itemDetails.image_name}
                                            alt={itemDetails.integration_name}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ProductModal>}
            </div>
        </>
    )
}
