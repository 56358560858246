/* eslint-disable no-undef */

import React, { Component } from "react";
// import PropTypes from 'prop-types'
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import {
  fetchJobs,
  sendFinishEmail,
  loadMoreJobApps,
  markAsReviewed,
  fetchApplicants,
} from "../actions/jobs";

import "../styles/JobsBody.css";

import Loading from "./ui/Loading";

import Indeed from "./jobsites/Indeed";
import ZipRecruiter from "./jobsites/ZipRecruiter";
import { oldAppPath } from "../constants/constant";
import { isEmpty } from "lodash";
import ReactPaginate from "react-paginate";

class JobsBody extends Component {
  constructor(props) {
    super(props);

    this.state = { expandedAppID: 0, jobStates: {}, applicantsData: [] };

    this.fetchJobs = this.fetchJobs.bind(this);
    this.expandJobApp = this.expandJobApp.bind(this);
    this.sendFinishEmail = this.sendFinishEmail.bind(this);
    this.convertTime = this.convertTime.bind(this);
    this.loadMoreApps = this.loadMoreApps.bind(this);
    this.toggleJobExpansion = this.toggleJobExpansion.bind(this);
    this.minAll = this.minAll.bind(this);
    this.markAsReviewed = this.markAsReviewed.bind(this);
  }

  // componentWillMount() {
  //   const { jobs } = this.props;

  //   if (jobs.length === 0) {
  //     this.fetchJobs();
  //   }
  // }
  componentDidMount() {
    const { applicantsData } = this.state;
    if (applicantsData.length === 0) {
      this.fetchJobs();
    }
  }

  // async fetchJobs() {
  //   const { dispatch } = this.props;

  //   const { response } = await dispatch(fetchJobs("applcant"));
  //   if (response && response.entities) {
  //     this.setState({
  //       applicantsData: Object.values(response.entities.jobs).map((item) => ({
  //         ...item,
  //         open: false,
  //         loading: false,
  //         currentPage: 0,
  //       })),
  //     });
  //   }
  // }

  async fetchJobs() {
    const { dispatch } = this.props;
    try {
      const { response } = await dispatch(fetchJobs("applicant"));
      if (response && response.entities && response.entities.jobs) {
        this.setState({
          applicantsData: Object.values(response.entities.jobs).map((item) => ({
            ...item,
            open: false,
            loading: false,
            currentPage: 0,
          })),
        });
      } else {
        console.warn("No jobs found in the response.");
        this.setState({ applicantsData: [] });
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
    }
  }

  expandJobApp(newjobID) {
    const { expandedAppID } = this.state;

    if (newjobID === expandedAppID) {
      this.setState({ expandedAppID: 0 });
      return;
    }

    this.setState({ expandedAppID: newjobID });
  }

  minAll() {
    const { applicantsData } = this.state;
    let copy = [...applicantsData];
    copy = copy.map((item) => ({ ...item, open: false }));
    this.setState({ applicantsData: copy });
  }

  fetchApplicantsData = async (jobID, index, record, toggle) => {
    const { dispatch } = this.props;
    const { applicantsData } = this.state;
    const copy = [...applicantsData];
    copy[index].loading = true;

    if (toggle) copy[index].open = !copy[index].open;

    this.setState({ applicantsData: copy });

    if (!isEmpty(copy[index].applications) && toggle) {
      copy[index].loading = false;
      this.setState({ applicantsData: copy });
      return;
    }

    if (copy[index].application_count === 0) {
      copy[index].applications = [];
      copy[index].loading = false;
      this.setState({ applicantsData: copy });
      return;
    }

    const { response } = await dispatch(fetchApplicants(jobID, record));
    if (response) {
      copy[index].applications = Object.values(response);
      copy[index].loading = false;
      copy[index].currentPage = record;
    }
    this.setState({ applicantsData: copy });
  };

  toggleJobExpansion(jobID, index) {
    this.fetchApplicantsData(jobID, index, 1, true);
  }

  convertTime(timeInMillis) {
    var d = new Date(0);
    d.setUTCSeconds(timeInMillis / 1000);
    return d.toDateString();
  }

  sendFinishEmail(appID) {
    const { dispatch } = this.props;

    dispatch(sendFinishEmail(appID));
  }

  markAsReviewed(appID) {
    const { dispatch } = this.props;

    dispatch(markAsReviewed(appID));
  }

  loadMoreApps(jobOPID, countWeAlreadyHave) {
    const { dispatch } = this.props;
    const pageWeNeed = Math.floor(countWeAlreadyHave / 10) + 1;

    dispatch(loadMoreJobApps(jobOPID, pageWeNeed));
  }

  handlePageClick = (event, jobID, index) => {
    this.fetchApplicantsData(jobID, index, event.selected + 1, false);
  };

  redirectTo = (url) => {
    const { location } = this.props;
    const urlData =
      location.pathname.split("/")[1] === "old"
        ? `${oldAppPath}/integrations/${url}`
        : `/app/integrations-app/${url}`;
    return urlData;
  };

  render() {
    const { isFetching, idUpdating, integrations } = this.props;
    const { expandedAppID, applicantsData } = this.state;

    return (
      <div className="JobsBody">
        <div className="instructions disappearSmall">
          This integration will automatically post your job requisitions from
          OnePoint to popular job boards. When applicants apply on those boards,
          they will receive an email with instructions on how to apply directly
          on OnePoint. Make sure the email address under the Product Settings
          header is correct because job sites require it in case they need to
          contact you directly.
        </div>
        <div className="header">
          <h2>Talent management</h2>
        </div>
        <div className="boards">
          {Array.isArray(integrations) &&
            integrations.map((int) => (
              <Link key={int.url} to={this.redirectTo(int.url)}>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginRight: "10px",
                  }}
                >
                  <div
                    className="board"
                    style={{
                      backgroundImage: "url(/images/" + int.image_name + ")",
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundColor: "rgb(238, 238, 238)",
                    }}
                  ></div>
                  <div
                    className="label"
                    style={{
                      backgroundColor: int.settings.enabled ? "#0cad4d" : "red",
                    }}
                  >
                    {int.settings.enabled ? "Enabled" : "Disabled"}
                  </div>
                </div>
              </Link>
            ))}
        </div>
        <div className="header">
          <div className="left">
            <h2>Applicants</h2>
            <div>
              <button onClick={() => this.fetchJobs()}>REFRESH</button>
            </div>
          </div>
          <div>
            <button onClick={() => this.minAll()}>Minimize All</button>
          </div>
        </div>
        {isFetching ? (
          <Loading />
        ) : (
          <div>
            {applicantsData && applicantsData.length === 0 && (
              <div className="jobCont">
                Your currently have no open job requisitions
              </div>
            )}
            {Array.isArray(applicantsData) &&
              applicantsData.map((job, index) => (
                <div className="jobCont" key={job.id}>
                  <div
                    className="jobTitle"
                    onClick={() => this.toggleJobExpansion(job.id, index)}
                  >
                    <div>
                      {job.title} <span>({job.application_count})</span>
                    </div>
                    <div>
                      {!job.open ? <button>+</button> : <button>-</button>}
                    </div>
                  </div>
                  {job.loading ? (
                    <Loading />
                  ) : (
                    job.open && (
                      <div className="appsCont">
                        {Array.isArray(job.applications) &&
                          job.applications.length === 0 ? (
                          <div>No applications yet</div>
                        ) : (
                          <div>
                            {Array.isArray(job.applications) &&
                              job.applications.map((app) => (
                                <div
                                  className={
                                    expandedAppID === app.id
                                      ? "expanded app"
                                      : "nonexpanded app"
                                  }
                                  key={app.id}
                                >
                                  <div
                                    onClick={() => this.expandJobApp(app.id)}
                                    className={
                                      app.user_reviewed
                                        ? "top reviewed"
                                        : "top notReviewed"
                                    }
                                  >
                                    <div className="nameCont">
                                      <div className="name">
                                        {app.applicant_name}
                                      </div>
                                      <div
                                        className={
                                          app.finish_email_sent
                                            ? "sent email"
                                            : "notsent email"
                                        }
                                      >
                                        {app.finish_email_sent ? (
                                          <span>SENT APPLY EMAIL</span>
                                        ) : (
                                          <span>APPLY EMAIL NOT SENT</span>
                                        )}
                                      </div>
                                    </div>
                                    <div className="rightSide">
                                      {app.user_reviewed && (
                                        <div className="reviewed">&#10003;</div>
                                      )}
                                      <div className="sourceTime">
                                        {app.source},{" "}
                                        {this.convertTime(
                                          app.applied_on_millis
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {expandedAppID === app.id &&
                                    app.source === "Indeed" && (
                                      <div className="bottom">
                                        <Indeed
                                          markAsReviewed={() =>
                                            this.markAsReviewed(app.id)
                                          }
                                          idUpdating={idUpdating}
                                          application={app}
                                          sendEmail={() =>
                                            this.sendFinishEmail(app.id)
                                          }
                                        />
                                      </div>
                                    )}
                                  {expandedAppID === app.id &&
                                    app.source === "ZipRecruiter" && (
                                      <div className="bottom">
                                        <ZipRecruiter
                                          markAsReviewed={() =>
                                            this.markAsReviewed(app.id)
                                          }
                                          idUpdating={idUpdating}
                                          application={app}
                                          sendEmail={() =>
                                            this.sendFinishEmail(app.id)
                                          }
                                        />
                                      </div>
                                    )}
                                </div>
                              ))}
                          </div>
                        )}
                        {job.application_count > 10 && (
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            forcePage={Number(job.currentPage) - 1}
                            onPageChange={(event) =>
                              this.handlePageClick(event, job.id, index)
                            }
                            pageCount={Math.ceil(job.application_count / 10)}
                            previousLabel="Previous"
                            containerClassName={"pagination-container"}
                            previousLinkClassName={"pagination__link"}
                            nextLinkClassName={"pagination__link"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                          />
                        )}
                        {/* {Array.isArray(job.applications) &&
                          job.applications.length < job.application_count && (
                            <div className="loadMore">
                              <button
                                onClick={() =>
                                  this.loadMoreApps(
                                    job.id,
                                    job.applications.length
                                  )
                                }
                              >
                                Load More
                              </button>
                            </div>
                          )} */}
                      </div>
                    )
                  )}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { list, isFetching, idUpdating } = state.jobs;
  const { jobs } = state.entities;

  const jobsListJoin =
    list === undefined
      ? []
      : list.map((jobid) => {
        let thisJob = Object.assign({}, jobs[jobid]);
        // const appsJoin = thisJob.applications.map(
        //   (appID) => applications[appID]
        // );
        // thisJob.applications = appsJoin;
        return thisJob;
      });

  return {
    isFetching,
    jobs: jobsListJoin,
    idUpdating,
  };
};

export default withRouter(connect(mapStateToProps)(JobsBody));
