import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const MarketPlaceList = ({ integration_name, image_name, description, ThemeFontstyle }) => {
    const truncateText = (text, wordLimit) => {
        const words = text.split(' ');
        return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '......' : text;
    };
    return (
        <div className="market-place-card-outer">
            <div className="market-place-card marketcard-border">
                <div className="market-logo">
                    <img src={image_name} alt={integration_name} />
                </div>
                <h2 className="mb-1"
                    style={{ fontStyle: `${ThemeFontstyle.fontsizestyle}`, textDecoration: ThemeFontstyle.fontsizestyle === 'underline' ? 'underline' : 'none' }}
                >{integration_name}</h2>
                <div className="description">
                    <p className="m-0">{truncateText(description, 7)}</p>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return { ThemeFontstyle: state.colortheme };
};

export default withRouter(connect(mapStateToProps)(MarketPlaceList));
