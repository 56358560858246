import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import { getToken } from '../../actions/auth'
import toast from 'react-hot-toast'
import axios from 'axios'
import moment from 'moment'

export default function Announcement(props) {
    const { setannouncementEdit, permissionUser, announcementEdit, announcement, refresh, setRefresh } = props
    const [value, setValue] = useState('')
    const [formatedDate, setFormatedDate] = useState('25-01-2025')
    const [dateValues, setDateValues] = useState('00-00-2025')
    const handleEditAnnounce = () => {
        setannouncementEdit(true)
    }
    const closeAnnounce = () => {
        setannouncementEdit(false)
    }
    const values = value && value.dates;
    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ size: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
            ],
            ["link", "image", "video"],
            ["clean"],
        ],
        clipboard: {
            matchVisual: false,
        },
    };
    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
    ];
    const handleChange = (value) => {
        if (dateValues === '00-00-2025') {
            setValue({ announcement: value, dates: values })
        }
        else {
            setValue({ announcement: value, ...dateValues });
        }
    };
    const AnnouncementData = announcement && announcement.length > 0 && announcement.find(item => item.item_name === "announcement_&_dates")

    useEffect(() => {
        setValue(AnnouncementData && AnnouncementData.config[0])
    }, [AnnouncementData])

    const monthName = moment(values, "DD-MM-YYYY", true).format("MMMM");
    const DateName = moment(values, "DD-MM-YYYY", true).format("DD");

    const dateValue = (e) => {
        const formattedDate = moment(e.target.value).format('DD-MM-YYYY');
        setValue({ announcement: value.announcement, dates: formattedDate });
        setDateValues({ dates: formattedDate })
    }


    const SubmitAnnouncement = async () => {
        try {
            const { token, userToken } = getToken();
            const requestBody = {
                item_name: "announcement_&_dates",
                config: JSON.stringify([{
                    dates: value.dates,
                    announcement: value.announcement
                }])
            };

            const response = await axios.post(
                "/api/sf/user-dashboard-config",
                requestBody,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            console.log('Response:-', response);
            setRefresh(!refresh)
            closeAnnounce()
            toast.success("Created Successfully !");
        } catch (error) {
            console.log('Error:-', error)
        }
    }

    const handleKeyPress = (event) => {
        switch (event.key) {
            case ' ':
                if (value.announcement.trim() === '<p><br></p>' ||
                    value.announcement.trim() === "<h2><br></h2>" ||
                    value.announcement.trim() === "<p> </p>" ||
                    value.announcement.trim() === '') {
                    setValue({ announcement: '' });
                }
                break;

            case 'Enter':
                if (value.announcement.trim() === '<p><br></p>' ||
                    value.announcement.trim() === "<p> </p>" ||
                    value.announcement === '<p> </p><p><br></p>' ||
                    value.announcement.trim() === '<p><br></p><p><br></p>' ||
                    value.announcement.trim() === '<h2><br></h2><p><br></p>') {
                    setValue({ announcement: '' });
                    event.preventDefault();
                }
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        if (values) {
            const formattedValue = `${value && values.split('-')[2]}-${value && values.split('-')[1]}-${value && values.split('-')[0]}`;
            setFormatedDate(formattedValue)
        }
        else {
            setFormatedDate('25-01-2025')
        }
    }, [values])
    const today = new Date().toISOString().split('T')[0];
    return (
        <>
            <button className='announcement-right_form'>
                <p style={{ marginLeft: '15px' }}>Announcements & dates to remember!</p>
                {permissionUser === true &&
                    <>
                        {announcementEdit ? (
                            <div className="topic_close" onClick={closeAnnounce}>
                                <FontAwesomeIcon icon={solid("xmark")} />
                            </div>
                        ) : (
                            <span className="edit-icon-btn" onClick={() => handleEditAnnounce(0)}>
                                <FontAwesomeIcon icon={solid("pen-to-square")} />
                            </span>
                        )}
                    </>}
            </button>
            <div className={`${announcementEdit === true ? 'announcementleft-inner annouce-remember' : 'announcementleft-inner'} `}>
                {announcementEdit === false ?
                    <div className='announcement-calender'>
                        <div className="announcementcalend-head">
                            <div className='cal_hole'></div>
                            <h4>{monthName}</h4>
                            <div className='cal_hole_left'></div>
                        </div>
                        <h1 className='date_tag'>
                            {DateName === "Invalid date" ? "" : DateName}
                        </h1>
                    </div> :
                    <div className="calendar-picker-container sync_history_mar">
                        <label htmlFor="start-date">Date</label>
                        <input type="date"
                            id="start-date"
                            name="start-date"
                            className='start_date_dash'
                            value={formatedDate}
                            onChange={dateValue}
                            min={today}
                        />
                        <div className='anc-edit-btn'>
                            <button
                                disabled={!value || value.announcement === "<h2><br></h2>"
                                    //  selectedDate.dates === '' || selectedDate.dates === "Invalid date" || selectedDate.dates === undefined
                                    || value.announcement === "" || value.announcement === '<p> </p>' || value.announcement === '<p><br></p>'
                                }
                                onClick={SubmitAnnouncement}>Save</button>
                        </div>
                    </div>
                }
                {announcementEdit === false ? <div dangerouslySetInnerHTML={{ __html: value && value.announcement ? value.announcement : 'No data fount' }} /> :
                    <>
                        <div className="description">
                            <ReactQuill style={{ height: '75%' }}
                                theme="snow"
                                name="announcement"
                                onKeyDown={handleKeyPress}
                                value={value && value.announcement}
                                onChange={handleChange}
                                modules={modules}
                                formats={formats}
                            />
                        </div>

                    </>
                }
            </div>
        </>
    )
}
