import React, { useState } from 'react'
import Modal from '../../../components/ui/Modal'
import axios from 'axios';
import toast from 'react-hot-toast';
import Loading from '../../../components/ui/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
const CompanyLogin = (props) => {
    const { OpenLoginModal, loginCompany } = props
    const [userName, setUserName] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [isloading, setIsLoading] = useState(false)
    const [passwordType, setPasswordType] = useState('password')

    const Relogin = async () => {
        setIsLoading(true)
        try {
            const data = {
                company_short: loginCompany,
                username: userName,
                password: userPassword,
                is_admin: true
            }
            console.log(data)
            const response = await axios.post(
                "/api/login",
                data,
            );
            localStorage.removeItem("isonePoint")
            // localStorage.setItem('userAdminPermission', 'false')
            localStorage.setItem("isAdmin", true);
            if (response) {
                localStorage.setItem('opintx_token', response.data.token);
                localStorage.setItem('opintx_user_token', response.data.user_token);
                localStorage.setItem('sites', response.data.sites_key);
                window.location.reload()
            }
            window.location = `/dashboard`
        } catch (error) {
            toast.error(error && error.response && error.response.data && error.response.data.error);
        }
        setIsLoading(false)
    }

    const eyeIcon = () => {
        switch (passwordType) {
            case 'password':
                setPasswordType('text');
                break;
            case 'text':
                setPasswordType('password');
                break;
            default: setPasswordType('password');
        }
    }
    
    return (
        <div>
            <Modal closeModal={OpenLoginModal}>
                <>
                    <div className="career-model login-comp">
                        <h1 className="text-center">Login</h1>
                        <div className="rowdata">
                            {isloading === true ?
                                <Loading /> :
                                <div className="imp-container-report">
                                    <div className="my-flex ">
                                        <div className="one-col-4 my-flex my-align-item-center mb-2">
                                            <strong>
                                                Company Short Name <span className="color-red"></span>
                                            </strong>
                                        </div>
                                        <div className="one-col-2 my-flex-grow-1 mb-2">
                                            <div className="my-flex">
                                                <div className="one-col-12">
                                                    <div className="one-control-input">
                                                        <input
                                                            type="text"
                                                            value={loginCompany}
                                                            // onChange={() => { }}
                                                            placeholder="Company Short Name"
                                                            name="CompanyShortName"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-flex ">
                                        <div className="one-col-4 my-flex my-align-item-center mb-2">
                                            <strong>
                                                Username <span className="color-red"></span>
                                            </strong>
                                        </div>
                                        <div className="one-col-2 my-flex-grow-1 mb-2">
                                            <div className="my-flex">
                                                <div className="one-col-12">
                                                    <div className="one-control-input">
                                                        <input
                                                            type="text"
                                                            value={userName}
                                                            onChange={(e) => { setUserName((e.target.value).trimStart()) }}
                                                            placeholder="Username"
                                                            name="name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-flex ">
                                        <div className="one-col-4 my-flex my-align-item-center mb-2">
                                            <strong>
                                                Password <span className="color-red"></span>
                                            </strong>
                                        </div>
                                        <div className="one-col-2 my-flex-grow-1 mb-2">
                                            <div className="my-flex">
                                                <div className="one-col-12">
                                                    <div className="one-control-input" style={{ display: 'flex' }}>
                                                        <input
                                                            type={passwordType}
                                                            value={userPassword}
                                                            onChange={(e) => { setUserPassword((e.target.value).trimStart()) }}
                                                            placeholder="Password"
                                                            name="name"
                                                            required
                                                        />
                                                        <span className='close-eye' onClick={eyeIcon}>
                                                            {passwordType === 'password' ? <FontAwesomeIcon icon={solid("eye")} /> :
                                                                <FontAwesomeIcon icon={solid("eye-slash")} />}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="my-flex my-justify-content-end">
                                        <button
                                            className="one-control-btn" style={{ width: '20%', marginRight: '5px' }}
                                            onClick={Relogin}
                                            disabled={(userName === "" || userPassword === "") ? true : false}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </>
            </Modal>
        </div>
    )
}
export default CompanyLogin;