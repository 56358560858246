import React, { useState } from 'react'
import ReactModal from 'react-modal-resizable-draggable';
import { getToken } from '../../../actions/auth';
import axios from 'axios';
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import gif from '../../../assets/images/loading-op.gif'
import { dynamicloader, dynamicloadercolor, dynamifontfamily, dynamifontsize, themecolorToggle, thememodalpoup } from '../../../actions/colortheme';
import Loading from "../../../components/ui/Loading";
import Loader from "react-js-loader";
import ThemeHideShow from '../../../components/ui/ThemeHideShow';
const ColorTheme = (props) => {
    const loader = localStorage.getItem('loader')
    const { CompanyId, dispatch, themeModal, CustomizeTheme } = props
    const fontandstyle = CustomizeTheme && CustomizeTheme.themecustomize && CustomizeTheme.themecustomize.theme_font_style && CustomizeTheme.themecustomize.theme_font_style.product_card_font_style
    const colorCode = CustomizeTheme &&
        CustomizeTheme.themecustomize &&
        CustomizeTheme.themecustomize.theme_loader &&
        CustomizeTheme.themecustomize.theme_loader.loader_color
    const [fontFamilyName, setFontFamilyName] = useState(CustomizeTheme.themecustomize.theme_font_style.font_family)
    const [productHeadingColor, setProductHeadingColor] = useState(fontandstyle.font_style_heading.font_color)
    const [paragraphcolor, setParagraphcolor] = useState(fontandstyle.font_style_paragraph.font_color)
    const [headingFontStyle, setHeadingFontStyle] = useState(fontandstyle.font_style_heading.font_style)
    const [headingFontSize, setHeadingFontSize] = useState(fontandstyle.font_style_heading.font_size)
    const [paragraphStyle, setParagraphStyle] = useState(fontandstyle.font_style_paragraph.font_style)
    const [paragraphFontSize, setParagraphFontSize] = useState(fontandstyle.font_style_paragraph.font_size)
    const [cardColor, setCardColor] = useState(fontandstyle.font_style_heading.font__bg_color)
    const [loaderColorVal, setLoaderColorVal] = useState(colorCode)
    const [loaderIcon, setLoaderIcon] = useState(loader)
    const [bgThemeColorValue, setbgThemeColorValue] = useState('')
    const [Lodding, setLodding] = useState(false);
    const [colorValues, setColorValue] = useState('')
    const [loaderName, setLoaderName] = useState('')
    const [logoName, setLogoName] = useState('')
    const [logoFile, setLogoFile] = useState('')
    const loaderTypes = [
        "heart",
        "hourglass",
        "spinner-cub",
        "rectangular-ping",
        "box-up",
        "spinner-circle",
        "spinner-default",
        "bubble-ping",
        "box-rotate-x",
        "bubble-spin",
        "box-rectangular",
        "box-rotate-y",
        "box-rotate-z",
        "ekvalayzer",
        "bubble-loop",
        "bubble-top",
        "bubble-scale",
        "ping-cube"
    ];
    const Fontstyles = [
        { value: '', label: 'Select Font Style' },
        { value: 'bold', label: 'Bold' },
        { value: 'italic', label: 'Italic' },
        { value: 'normal', label: 'Normal' },
        { value: 'oblique', label: 'Oblique' },
        { value: 'inherit', label: 'Inherit' },
        { value: 'initial', label: 'Initial' },
        // { value: 'underline', label: 'Underline' }
    ];
    const FontFamaily = [
        { value: 'slick', label: 'Slick' },
        { value: 'Roboto', label: 'Roboto' },
        { value: 'auto', label: 'Auto' },
        { value: 'cursive', label: 'Cursive' },
        { value: 'fangsong', label: 'Fangsong' },
        { value: 'fantasy', label: 'Fantasy' },
        { value: 'monospace', label: 'Monospace' },
        { value: 'none', label: 'None' },
        { value: 'sans-serif', label: 'Sans-serif' },
        { value: 'serif', label: 'Serif' },
        { value: 'system-ui', label: 'System-ui' },
    ]

    const handlethemeToggle = () => {
        dispatch(themecolorToggle());
        handlethememodal()
    };

    const handlethememodal = () => {
        dispatch(thememodalpoup());
    };

    const colorPara = (e) => {
        const paracolor = e.target.value
        document.documentElement.style.setProperty('--paracolor', paracolor);
        setParagraphcolor(paracolor)
    }

    const colorValue = (e) => {
        const color = e.target.value
        document.documentElement.style.setProperty('--my-color', color);
        localStorage.setItem('myColor', color);
        setColorValue(color)
    }

    const bgColorChange = (e) => {
        const bg = e.target.value
        document.documentElement.style.setProperty('--bgcolor', bg);
        setCardColor(bg)
    }

    const ColorChange = (e) => {
        const procolor = e.target.value
        document.documentElement.style.setProperty('--productcolor', procolor);
        setProductHeadingColor(procolor)
        // localStorage.setItem('proColor', procolor);
    }
    const BgChange = (e) => {
        const bgcolor = e.target.value
        document.documentElement.style.setProperty('--background', bgcolor);
        localStorage.setItem('Colorbg', bgcolor);
        setbgThemeColorValue(bgcolor)
    }
    const widthvalue = localStorage.getItem('widthval')
    const storedColor = localStorage.getItem('myColor');
    const ColorBg = localStorage.getItem('Colorbg');
    const heightvalue = localStorage.getItem('heightval')

    console.log('heightvalue',heightvalue);
    

    const handleSave = async (event) => {
        event.stopPropagation();
        try {
            const { token } = getToken();
            setLodding(true)
            var data = {
                company_id: JSON.stringify(CompanyId),
                contents: [
                    {
                        content_type: 'theme_color',
                        content: `{"color":"${storedColor}","bg_color":"${ColorBg}"}`
                    },
                    {
                        content_type: 'theme_dimention',
                        content: `{"product_Card_dimention":{"height":"${heightvalue}px","width":"${widthvalue}px"},"sidebar_dimention":{"height":"800px","width":"450px"}}`
                    },
                    {
                        content_type: 'theme_loader',
                        content: `{"loader_type":"${loaderName ? loaderName : loaderIcon}","loader_color":"${loaderColorVal}"}`
                    },
                    {
                        content_type: 'theme_font_style',
                        content: `{"font_family":"${fontFamilyName}","product_card_font_style":{"font_style_heading":{"font_style":"${headingFontStyle}","font_size":"${headingFontSize}","font_color":"${productHeadingColor}","font__bg_color":"${cardColor}"},
                        "font_style_paragraph":{"font_style":"${paragraphStyle}","font_size":"${paragraphFontSize}","font_color":"${paragraphcolor}"}}}`
                    }
                ]
            };
            const response = await axios.post(
                "/api/customize/panel",
                data,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            setLodding(false);
            if (response.data) {
                console.log("Response:", response.data);
                handlethememodal()
                window.location.reload()
            }
        } catch (error) {
            setLodding(false);
            toast.error("Invalid request error")
            console.log("Error:", error);
            console.log({colorValues},{bgThemeColorValue})
        }
        localStorage.setItem('loader', loaderIcon)
    }

    //dynamic loader
    const LoaderColor = (e) => {
        const loader = e.target.value
        setLoaderColorVal(loader)
        localStorage.setItem('loadercolor', loader)
        dispatch(dynamicloadercolor(loader))
    }
    // const bgcolor = localStorage.getItem('myColor')
    const handleIconClick = (type) => {
        setLoaderIcon(type)
        toast.success("Loader selected")
        // localStorage.setItem('loader', type)
        dispatch(dynamicloader(type))
    };

    const handleStyleChange = (event) => {
        dispatch(dynamifontsize(event.target.value));
        setHeadingFontStyle(event.target.value)
        document.documentElement.style.setProperty('--prohendingfontstyle', event.target.value);
    }

    const handleParaStyleChange = (event) => {
        setParagraphStyle(event.target.value)
        document.documentElement.style.setProperty('--paragraphfontstyle', event.target.value);
    }

    const handleFontfamilyChange = (e) => {
        dispatch(dynamifontfamily(e.target.value))
        setFontFamilyName(e.target.value)
        document.documentElement.style.setProperty('--fontFamily', e.target.value);
    }
    const defaultLoader = (e) => {
        setLoaderName(e.target.alt)
        toast.success("Loader select")
    }
    const loaderdata = loader === 'defaultloader' ? <img src={gif} alt="loading" className="drag-loader" /> : <Loading />
    const handleHeadingsize = (e) => {
        const value = e.target.value;
        setHeadingFontSize(`${value}px`)
        document.documentElement.style.setProperty('--proheadingfontsize', `${value}px`);
    }

    const ParagraphFontsize = (e) => {
        const value = e.target.value;
        setParagraphFontSize(`${value}px`)
        document.documentElement.style.setProperty('--paragraphfontsize', `${value}px`);
    }

    const handleLogo = (e, name) => {
        const { files } = e.target;
        const file = files[0];
        if (files.length > 0) {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const validExtensions = ['jpg', 'jpeg', 'png'];

            if (!validExtensions.includes(fileExtension)) {
                setLogoFile('')
                toast.error('Invalid file type. Please select image file.');
                return;
            }
            setLogoFile(file)
            setLogoName(name)
        }
    }
    const submitLogoImage = async () => {
        var data = new FormData();
        data.append('logo', logoFile);
        try {
            const { token, userToken } = getToken();
            setLodding(true)
            await axios.post(
                "/api/upload/logo",
                data,
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            setLodding(false)
            handlethememodal()
            window.location.reload()
        } catch (error) {
            setLodding(false)
            console.log('Error:-', error);
        }
    }


    return (
        <div>
            <ReactModal
                initWidth={560}
                initHeight={400}
                className={"my-modal-custom-class"}
                isOpen={themeModal}>
                <div className='dramodal'>
                    <h3 className='modalTitle'>Theme Setting</h3>
                </div>
                {Lodding === true ?
                    // <img src={gif} alt="loading" className="drag-loader" /> :
                    <div style={{ marginTop: '100px' }}>
                        {loaderdata}
                    </div> :
                    <div className="dragbody themesetting-outer">
                        <div className="product-setting-inner email-notification mb-2">
                            <ThemeHideShow
                                label={
                                    <label htmlFor="" className='show-Icon loader-plus-iconlement loader-plus-iconlement'>Color
                                    </label>
                                }
                                body={
                                    <div>
                                        {/* <h3 className='Cstmsubtitle mt-0'></h3> */}
                                        <hr className='Cstmsubtitle mt-0' />
                                        <div className='Cstmdraginp'>
                                            <input type="color"
                                                value={storedColor}
                                                onChange={(e) => { colorValue(e) }}
                                            />Sidebar & Nav
                                        </div>
                                        <div className='draginp'>
                                            <input type="color"
                                                value={ColorBg}
                                                onChange={(e) => { BgChange(e) }}
                                            />Background
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className="product-setting-inner email-notification mb-2">
                            <ThemeHideShow
                                label={
                                    <label htmlFor="" className='show-Icon loader-plus-iconlement'>Dimension
                                        {/* <span className='plusIcons' >
                                        </span> */}
                                    </label>
                                }
                                body={
                                    <div>
                                        <hr className='Cstmsubtitle mt-0' />
                                        <div className='Cstmdraginp CstmFlex' onClick={() => { handlethemeToggle() }}>
                                            <span>
                                                {/* <img
                                            src="/images/logo/dimensionsimg.svg"
                                            alt="twobuilding" /> */}
                                                <svg width="32px" height="32px" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M3.00014 2.73895C3.00014 2.94698 2.76087 3.06401 2.59666 2.93628L1.00386 1.69744C0.875177 1.59735 0.875177 1.40286 1.00386 1.30277L2.59666 0.063928C2.76087 -0.0637944 3.00014 0.0532293 3.00014 0.261266V1.00012H9.00009V0.261296C9.00009 0.0532591 9.23936 -0.0637646 9.40358 0.0639578L10.9964 1.3028C11.1251 1.40289 11.1251 1.59738 10.9964 1.69747L9.40358 2.93631C9.23936 3.06404 9.00009 2.94701 9.00009 2.73898V2.00012H3.00014V2.73895ZM9.50002 4.99998H2.50002C2.22388 4.99998 2.00002 5.22384 2.00002 5.49998V12.5C2.00002 12.7761 2.22388 13 2.50002 13H9.50002C9.77616 13 10 12.7761 10 12.5V5.49998C10 5.22384 9.77616 4.99998 9.50002 4.99998ZM2.50002 3.99998C1.67159 3.99998 1.00002 4.67156 1.00002 5.49998V12.5C1.00002 13.3284 1.67159 14 2.50002 14H9.50002C10.3284 14 11 13.3284 11 12.5V5.49998C11 4.67156 10.3284 3.99998 9.50002 3.99998H2.50002ZM14.7389 6.00001H14V12H14.7389C14.9469 12 15.064 12.2393 14.9362 12.4035L13.6974 13.9963C13.5973 14.125 13.4028 14.125 13.3027 13.9963L12.0639 12.4035C11.9362 12.2393 12.0532 12 12.2612 12H13V6.00001H12.2612C12.0532 6.00001 11.9361 5.76074 12.0639 5.59653L13.3027 4.00373C13.4028 3.87505 13.5973 3.87505 13.6974 4.00374L14.9362 5.59653C15.0639 5.76074 14.9469 6.00001 14.7389 6.00001Z" fill="#000000"></path> </g></svg>
                                            </span>
                                            <p style={{ marginBottom: '0rem', fontSize: '16px' }}>Product Card Dimension
                                            </p>
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className="product-setting-inner email-notification mb-2">
                            <ThemeHideShow
                                label={
                                    <label htmlFor="" className='show-Icon loader-plus-iconlement'>Font Style & Size
                                        {/* <span className='plusIcons-font'></span> */}
                                    </label>
                                }
                                body={
                                    <div>
                                        <hr className='Cstmsubtitle mt-0' />
                                        <div className='Cstmdraginp CstmFlex' style={{ marginBottom: '10px' }}>
                                            <h4 className='cus_font'>Select Font Family</h4>
                                            <select onChange={(e) => { handleFontfamilyChange(e) }}
                                                value={fontFamilyName}>
                                                <option key="disabled-option" value="" disabled>
                                                    Select Font family
                                                </option>
                                                {FontFamaily.map((style) => (
                                                    <option key={style.value} value={style.value}>
                                                        {style.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <ThemeHideShow
                                            label={
                                                <label htmlFor="" className='loader-plus-iconlement-sub' style={{ fontSize: '17px', marginTop: '20px' }}>Heading
                                                    <span className='sub-st-plusIcon'></span>
                                                </label>
                                            }
                                            body={
                                                <div style={{ marginTop: '20px' }}>

                                                    <div className='Cstmdraginp CstmFlex'>
                                                        <h4 className='cus_font'>Select Font Style</h4>
                                                        <select onChange={(e) => { handleStyleChange(e) }}
                                                            value={headingFontStyle} >
                                                            {Fontstyles.map((style) => (
                                                                <option key={style.value} value={style.value}>
                                                                    {style.label}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='Cstmdraginp CstmFlex'>
                                                        <h4 className='cus_font'>Select Font Size</h4>
                                                        <input type="number" placeholder='Font Size' value={headingFontSize.replace('px', '')} className='font_size' onChange={(e) => { handleHeadingsize(e) }} />
                                                    </div>
                                                    <div className='Cstmdraginp'>
                                                        <input type="color"
                                                            value={productHeadingColor}
                                                            onChange={(e) => { ColorChange(e) }}
                                                        />Heading Color
                                                    </div>
                                                    <div className='Cstmdraginp'>
                                                        <input type="color"
                                                            value={cardColor}
                                                            onChange={(e) => { bgColorChange(e) }}
                                                        />Background Color
                                                    </div>
                                                </div>
                                            }
                                        />
                                        <div style={{ marginTop: '30px' }}>
                                            <ThemeHideShow
                                                label={
                                                    <label htmlFor="" className='loader-plus-iconlement-sub' style={{ fontSize: '17px', marginLeft: '10px', marginTop: '20px' }}>Paragraph
                                                        <span className='sub-st-para-plusIcon'></span>
                                                    </label>
                                                }
                                                body={
                                                    <div style={{ marginTop: '20px' }}>
                                                        <div className='Cstmdraginp CstmFlex'>
                                                            <h4 className='cus_font'>Select Font Style</h4>
                                                            <select onChange={(e) => { handleParaStyleChange(e) }}
                                                                value={paragraphStyle} >
                                                                {Fontstyles.map((style) => (
                                                                    <option key={style.value} value={style.value}>
                                                                        {style.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className='Cstmdraginp CstmFlex'>
                                                            <h4 className='cus_font'>Select Font Size</h4>
                                                            <input type="number" placeholder='Font Size' value={paragraphFontSize.replace('px', '')} className='font_size' onChange={(e) => { ParagraphFontsize(e) }} />
                                                        </div>
                                                        <div className='Cstmdraginp'>
                                                            <input type="color"
                                                                value={paragraphcolor}
                                                                onChange={(e) => { colorPara(e) }}
                                                            />Paragraph Color
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className="product-setting-inner email-notification mb-2">
                            <ThemeHideShow
                                label={
                                    <label htmlFor="" className='show-Icon loader-plus-iconlement'>Loader
                                        {/* <span className='plusIcons-loader'></span> */}
                                    </label>
                                }
                                body={
                                    <div style={{ marginTop: '40px' }}>
                                        <ThemeHideShow
                                            label={
                                                <label htmlFor="" className='loader-plus-iconlement-sub' style={{ fontSize: '17px', marginTop: '20px' }}>Default
                                                    <span className='sub-plusIcon'></span>
                                                </label>
                                            }
                                            body={
                                                <div style={{ marginTop: '20px' }}>
                                                    <img src={gif} alt="defaultloader" className="drag-loader cus_loader" onClick={defaultLoader} />
                                                </div>
                                            }
                                        />
                                        <div style={{ marginTop: '35px' }}>
                                            <ThemeHideShow
                                                label={
                                                    <label htmlFor="" className='loader-plus-iconlement-sub' style={{ fontSize: '17px', marginTop: '20px' }}>Dynamic
                                                        <span className='sub-plusIcons'></span>
                                                    </label>
                                                }
                                                body={
                                                    <div>
                                                        <div className='Cstmdraginp'>
                                                            {loaderTypes.map((type, index) => (
                                                                <div className="loader-height" key={index}>
                                                                    <div className={"item"} onClick={() => handleIconClick(type)}>
                                                                        <Loader type={type} bgColor={'#000000'} color={'#000000'} size={50} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <h3 className='Cstmsubtitle mt-0' style={{ marginLeft: '45px', marginRight: '75px' }} >Loader Color</h3>
                                                        <div style={{ display: 'flex', marginTop: '20px' }}>
                                                            <div className='Cstmdraginp'>
                                                                <input type="color"
                                                                    style={{ marginLeft: '40px' }}
                                                                    value={loaderColorVal}
                                                                    onChange={(e) => { LoaderColor(e) }}
                                                                />Color
                                                            </div>
                                                            <div className="box-loader" style={{ marginLeft: '65px' }}>
                                                                <div className={"item"}>
                                                                    <Loader type={loaderIcon} bgColor={loaderColorVal} color={'#000000'} size={90} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            />
                                        </div>
                                    </div>
                                }
                            />
                        </div>
                        <div className="product-setting-inner email-notification mb-2">
                            <ThemeHideShow
                                label={
                                    <label htmlFor="" className='show-Icon loader-plus-iconlement loader-plus-iconlement'>Upload Logo
                                        {/* <span className='plusIcon_logo' >
                                        </span> */}
                                    </label>
                                }
                                body={
                                    <div>
                                        <hr className='Cstmsubtitle mt-0' />
                                        <input
                                            type="file"
                                            className="biz_lib_file"
                                            style={{ width: '95%', marginTop: '10px' }}
                                            onChange={(e) => { handleLogo(e, 'logoImage') }}
                                            accept="image/*"
                                        />
                                    </div>
                                }
                            />
                        </div>
                        <div className='drag-btn'>
                            <button onClick={logoName === 'logoImage' ? submitLogoImage : handleSave} className='drag-btn-close'>
                                Save
                            </button>
                            <button onClick={handlethememodal} className='drag-btn-close'>
                                Close
                            </button>
                        </div>
                    </div>
                }
            </ReactModal>
            <Toaster />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        CompanyId: state && state.products && state.products.companyInfo && state.products.companyInfo.op_id,
        themeModal: state && state.colortheme.thememodal,
        CustomizeTheme: state && state.colortheme
    };
};

export default withRouter(connect(mapStateToProps)(ColorTheme));