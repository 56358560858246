import React, { useState } from 'react'
import ProductModal from '../../UI/ProductModal';
import ReactPaginate from 'react-paginate';

export default function ConnecterCompany(props) {
    const { setOpenConCompany, ConCompanyData, filetrData, setFiletrData, productId, connecterCompany } = props

    const [rowPerPage, setRowPerPage] = useState(15);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const offset = currentPage * rowPerPage;
    const rowPerPageOptions = [15, 25, 50, 100];
    const pageCount = Math.ceil(
        filetrData.length / rowPerPage
    );
    const handleSelectSort = (e) => {
        setRowPerPage(Number(e.target.value));
        setCurrentPage(0);
    };

    const handlePageClick = ({ selected: selectedPage }) => {
        setCurrentPage(selectedPage);
    };

    const handleClose = () => {
        setOpenConCompany(false)
    }

    const handleSearchProduct = (e) => {
        const { value } = e.target;
        setSearchValue(value);
        if (value.trim() === '') {
            setFiletrData(ConCompanyData);
        } else {
            const filtered = ConCompanyData && ConCompanyData.filter((item) =>
                item.company_name.toLowerCase().includes(value.toLowerCase()) ||
                item.short_name.toLowerCase().includes(value.toLowerCase())
            );
            setFiletrData(filtered);
        }
        setCurrentPage(0);
    };

    const dataForTable = filetrData && filetrData.slice(offset, offset + rowPerPage)

    const exportToCSV = () => {
        const headers = ['Company Name', 'Short Name', 'Status'];
        const dataToExport = filetrData.length > 0 ? filetrData : dataForTable;
        const csvData = dataToExport.map((item) => {
            const shortName = item.short_name.replace(/,/g, '');
            const name = item.company_name.replace(/,/g, '');
            const status = item.status === 'connected' ? true : false
            const enabled = item.settings.enabled === true ? true : false
            const checkstatus = enabled ? 'CONNECTED' : 'DISABLED'
            const checkenabled = status && enabled ? 'CONNECTED AND ENABLED' : enabled ? 'ENABLED BUT NOT CONNECTED' : 'DISABLED'
            const finalstatus = productId === 1 ? checkenabled : checkstatus

            return [name, shortName, finalstatus].join(',');
        });
        const csvContent = [headers.join(','), ...csvData].join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${connecterCompany}_Connector_Companies.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    return (
        <div>
            <ProductModal heading={`${connecterCompany} Connector Company`} closeModal={handleClose}>
                <div className="company_system_table tabmars">
                    <div className="tbl_header tbl_header-bom">
                        <div className="page_upper flex-filter">
                            <div className="left_tsec flex-filter">
                                <div className="row_filter">
                                    <label htmlFor="rows">
                                        <b>Rows Per Page:</b>
                                    </label>
                                    <select
                                        id="rows"
                                        onChange={handleSelectSort}
                                        value={rowPerPage}
                                    >
                                        {rowPerPageOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="show_filter flex-filter">
                                    <div>
                                        <label>
                                            <b>Showing:</b>
                                        </label>
                                        <span style={{ marginLeft: '5px' }}>
                                            {offset + 1}-
                                            {Math.min(offset + rowPerPage, ConCompanyData.length)} of {ConCompanyData.length}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="right_tsec flex-filter">
                                <div style={{ display: 'flex' }}>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchValue}
                                        className="endStart"
                                        onChange={handleSearchProduct}
                                    />
                                    <button className="export-csv"
                                        onClick={exportToCSV}
                                        style={{ marginRight: '0px' }}>
                                        Export to CSV
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tbl_container">
                        <table>
                            <thead className='tab-head'>
                                <tr>
                                    <th className="tbl-td-th">S.No</th>
                                    <th className="tbl-td-th">Company Name</th>
                                    <th className="tbl-td-th">Short Name</th>
                                    <th className="tbl-td-th">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataForTable && dataForTable.map((item, index) => {
                                        const status = item.status === 'connected' ? true : false
                                        const enabled = item.settings.enabled === true ? true : false
                                        return (
                                            <tr key={index}>
                                                <td className="tbl-td-th">{index + 1}</td>
                                                <td className="tbl-td-th">{item.company_name}</td>
                                                <td className="tbl-td-th">{item.short_name}</td>
                                                {productId === 1 ? <td className="tbl-td-th">
                                                    {status && enabled ?
                                                        <span style={{ color: 'green' }}>CONNECTED AND ENABLED</span> : enabled ?
                                                            <span style={{ color: 'red' }}>ENABLED BUT NOT CONNECTED</span> :
                                                            <span style={{ color: 'red' }}>DISABLED</span>}
                                                </td> :
                                                    <td className="tbl-td-th">
                                                        {enabled ?
                                                            <span style={{ color: 'green' }}>CONNECTED</span> :
                                                            <span style={{ color: 'red' }}>DISABLED</span>}
                                                    </td>}
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        {dataForTable.length === 0 ?
                            <div className='pro-tab'>No Data found!</div> : ""}
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            previousLabel="Previous"
                            containerClassName="pagination-container"
                            previousLinkClassName="pagination__link"
                            nextLinkClassName="pagination__link"
                            disabledClassName="disabled"
                            activeClassName="active"
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                        />
                    </div>
                </div>
            </ProductModal>
        </div>
    )
}
