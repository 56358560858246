import React, { useEffect, useState } from "react";
import ProductModal from "../../UI/ProductModal";
import useFetch from "../../../hooks/useFetch";
import Loading from "../../../../components/ui/Loading";
import blankimg from "../../../../assets/images/images-pro.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import indeedImg from "../../../../assets/images/indeed.png";
import ziprecruiterImg from "../../../../assets/images/ziprecruiter.png";
import careerImg from "../../../../assets/images/career.png";
import bg_checksImg from "../../../../assets/images/bg_checks.png";

function Connecters(props) {
  const {
    setConnecterOpen,
    productId,
    setOpenConCompany,
    setConCompanyData,
    setFiletrData,
    productName,
    setConnecterCompany,
  } = props;
  const [fetchProductconnecter, ConnecterLoading] = useFetch();
  const [connecterData, setConnecterData] = useState([]);

  const getConnecter = async () => {
    try {
      const payload = {
        endPoint: `/api/product-integrations/${productId}`,
        method: "get",
      };
      const res = await fetchProductconnecter(payload);
      setConnecterData(res);

      console.log("Response:-", res);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getConnecter();
  }, [productId]);

  const handleClose = () => {
    setConnecterOpen(false);
  };

  const modalProductConnecter = (val) => {
    setOpenConCompany(true);
    setConCompanyData(val.companies);
    setFiletrData(val.companies);
    setConnecterCompany(val.name);
  };

  return (
    <div className="pro_connecter">
      <ProductModal
        heading={`${productName} Connectors`}
        closeModal={handleClose}
      >
        <div className="company_system_table tabmars">
          <div className="tbl_header tbl_header-bom">
            {/* <div className="page_upper flex-filter">
                            <div className="left_tsec flex-filter">
                                <div className="row_filter">
                                    <label htmlFor="rows">
                                        <b>Rows Per Page:</b>
                                    </label>
                                    <select
                                        id="rows"
                                        onChange={handleSelectSort}
                                        value={rowPerPage}
                                    >
                                        {rowPerPageOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="show_filter flex-filter">
                                    <div>
                                        <label>
                                            <b>Showing:</b>
                                        </label>
                                        <span style={{ marginLeft: '5px' }}>
                                            {offset + 1}-
                                            {Math.min(offset + rowPerPage, filetrData.length)} of {filetrData.length}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="right_tsec flex-filter">
                                <div style={{ display: 'flex' }}>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        // value={searchValue}
                                        className="endStart"
                                        // onChange={handleSearchProduct}
                                    />
                                    <button className="export-csv" style={{ marginRight: '0px' }}>
                                        Export to CSV
                                    </button>
                                </div>
                            </div>
                        </div> */}
          </div>
          <div className="tbl_container">
            {ConnecterLoading ? (
              <Loading />
            ) : (
              <table>
                <thead style={{ position: "sticky", top: 0, fontSize: "15px" }}>
                  <tr>
                    <th className="IconTh"> S.No</th>
                    <th className="IconTh">Icons</th>
                    <th>Integration Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {/* <tbody>
                                {(connecterData && connecterData.length === 0) || (connecterData && connecterData === null) ?
                                    <div className='pro-tab'>No Data found!</div> :
                                    connecterData && connecterData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td className='logo-pro-div'>
                                                    {productId === 2 ?
                                                        <img
                                                            src={item.ImgURL === "indeed.png" ? indeedImg : item.ImgURL === 'ziprecruiter.png'
                                                                ? ziprecruiterImg : item.ImgURL === 'bg_checks.png' ? bg_checksImg : item.ImgURL === 'career.png' ? careerImg : blankimg}
                                                            alt="img"
                                                            className='comp-pro-imgs'
                                                        /> :
                                                        <img
                                                            src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${(item.ImgURL) ? (item.ImgURL) : blankimg}`}
                                                            alt="img"
                                                            className='comp-pro-imgs'
                                                        />
                                                    }
                                                </td>
                                                <td style={{ paddingLeft: '15px' }}>{item.name}</td>
                                                <td style={{ paddingLeft: '15px',textAlign:'center' }}>
                                                    <button
                                                        onClick={() => { modalProductConnecter(item) }}
                                                        className="me-10-px"
                                                        title="Companies">
                                                        <FontAwesomeIcon icon={solid("eye")} />
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody> */}
                <tbody>
                  {!Array.isArray(connecterData) ||
                  connecterData.length === 0 ? (
                    <div className="pro-tab">No Data found!</div>
                  ) : (
                    connecterData.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="logo-pro-div">
                            {productId === 2 ? (
                              <img
                                src={
                                  item.ImgURL === "indeed.png"
                                    ? indeedImg
                                    : item.ImgURL === "ziprecruiter.png"
                                    ? ziprecruiterImg
                                    : item.ImgURL === "bg_checks.png"
                                    ? bg_checksImg
                                    : item.ImgURL === "career.png"
                                    ? careerImg
                                    : blankimg
                                }
                                alt="img"
                                className="comp-pro-imgs"
                              />
                            ) : (
                              <img
                                src={`https://storage.googleapis.com/onepoint-connect-public-images/connect-tile-images/${
                                  item.ImgURL ? item.ImgURL : blankimg
                                }`}
                                alt="img"
                                className="comp-pro-imgs"
                              />
                            )}
                          </td>
                          <td style={{ paddingLeft: "15px" }}>{item.name}</td>
                          <td
                            style={{ paddingLeft: "15px", textAlign: "center" }}
                          >
                            <button
                              onClick={() => {
                                modalProductConnecter(item);
                              }}
                              className="me-10-px"
                              title="Companies"
                            >
                              <FontAwesomeIcon icon={solid("eye")} />
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            )}
            {/* <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            previousLabel="Previous"
                            containerClassName="pagination-container"
                            previousLinkClassName="pagination__link"
                            nextLinkClassName="pagination__link"
                            disabledClassName="disabled"
                            activeClassName="active"
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                        /> */}
          </div>
        </div>
      </ProductModal>
    </div>
  );
}

export default Connecters;
