import React, { useEffect, useState } from 'react'
import ProductModal from '../../UI/ProductModal';
import useFetch from '../../../hooks/useFetch';
import Loading from '../../../../components/ui/Loading';

export default function ActivityReport(props) {
    const { setActivityPopup, activityId } = props
    const [fetchProductactivity, ActivityLoading] = useFetch();
    const [productActivities, setProductActivities] = useState([])
    const handleClose = () => {
        setActivityPopup(false)
    }

    const activityLogs = async () => {
        try {
            const payload = {
                endPoint: `/api/product/activity/logs/${activityId}`,
                method: "get",
            };
            const res = await fetchProductactivity(payload);
            setProductActivities(res)
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        activityLogs()
    }, [activityId])
    return (
        <div>
            <ProductModal heading="Activity Logs" closeModal={handleClose}>
                <div className="company_system_table tabmars">
                    <div className="tbl_header tbl_header-bom">
                        {/* <div className="page_upper flex-filter">
                            <div className="left_tsec flex-filter">
                                <div className="row_filter">
                                    <label htmlFor="rows">
                                        <b>Rows Per Page:</b>
                                    </label>
                                    <select
                                        id="rows"
                                        onChange={handleSelectSort}
                                        value={rowPerPage}
                                    >
                                        {rowPerPageOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="show_filter flex-filter">
                                    <div>
                                        <label>
                                            <b>Showing:</b>
                                        </label>
                                        <span style={{ marginLeft: '5px' }}>
                                            {offset + 1}-
                                            {Math.min(offset + rowPerPage, filetrData.length)} of {filetrData.length}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="right_tsec flex-filter">
                                <div style={{ display: 'flex' }}>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchValue}
                                        className="endStart"
                                        onChange={handleSearchProduct}
                                    />
                                    <button className="export-csv" onClick={exportToCSV} style={{ marginRight: '0px' }}>
                                        Export to CSV
                                    </button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="tbl_container" style={{marginBottom:'25px'}}>
                        {ActivityLoading ? <Loading /> :
                            <>
                                {productActivities && productActivities.length > 0 ?
                                    <table >
                                        <thead className='tab-head'>
                                            <tr>
                                                <th className="tbl-td-th">S.No</th>
                                                <th className="tbl-td-th">Company Short Name</th>
                                                <th className="tbl-td-th">Message</th>
                                                <th className="tbl-td-th">Date</th>
                                                <th className="tbl-td-th">Time</th>
                                            </tr>
                                        </thead>
                                        <tbody className='font_logs'>
                                            {productActivities &&
                                                productActivities.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="tbl-td-th">{index + 1}</td>
                                                            <td className="tbl-td-th">{item.company_short_name}</td>
                                                            <td className="tbl-td-th">{item.message}</td>
                                                            <td className="tbl-td-th">{item.date}</td>
                                                            <td className="tbl-td-th">{item.time}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                    : <span className='no-product'>No data founds</span>}
                                    </>
                        }
                        {/* <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            previousLabel="Previous"
                            containerClassName="pagination-container"
                            previousLinkClassName="pagination__link"
                            nextLinkClassName="pagination__link"
                            disabledClassName="disabled"
                            activeClassName="active"
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                        /> */}
                    </div>
                </div>
            </ProductModal>
        </div>
    )
}
