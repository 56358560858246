import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router";
import ToggleButton from "react-toggle-button";
import { getToken } from "../actions/auth";
import Select from "react-select";

import {
  fetchCPInfo,
  fetchCPInstalls,
  updateEmployee,
  fetchCPEmps,
  deleteCPEnrollment,
  updateInstallation,
  cloudPunchSettings,
  getCloudPunchSettings,
} from "../actions/cloudpunch";
import { fingerPositionNames } from "../constants/states";
import { fetchCompanyData } from "../actions/companyData";

import Loading from "./ui/Loading";
import Modal from "./ui/Modal";
import HideShow from "./ui/HideShow";
import ReactPaginate from "react-paginate";
import "../styles/CloudPunchBody.css";
import moment from "moment";
import { isEmpty } from "lodash";
import CloudPunchInstallationSettings from "./CloudPunchInstallationSettings";
import CloudPunchSetup from "./CloudPunchSetup";
import InstallationLogs from "./CloudPunchInstallationLogs";
import { userWritePermission } from "../actions/dashboard";

// useEffect(
//   fetchInstallationSetup()
// )

class AddCC extends Component {
  constructor(p) {
    super(p);
    this.state = {
      value:
        p.ccsAvailable && p.ccsAvailable.length > 0
          ? p.ccsAvailable[0].id
          : null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(np) {
    this.setState({
      value:
        np.ccsAvailable && np.ccsAvailable.length > 0
          ? np.ccsAvailable[0].id
          : null,
    });
  }

  handleChange(event) {
    this.setState({ value: Number(event.target.value) });
  }

  render() {
    const { ccsAvailable, onAdd } = this.props;

    if (!ccsAvailable || ccsAvailable.length === 0) {
      return <div>No more remaining</div>;
    }

    return (
      <div>
        <select value={this.state.value} onChange={this.handleChange}>
          {ccsAvailable.map((cc) => (
            <option key={`ccadd${cc.id}`} value={cc.id}>
              {cc.name}
            </option>
          ))}
        </select>
        <button
          onClick={() => onAdd(this.state.value)}
          className="addDelete add"
        >
          <div>+</div>
        </button>
      </div>
    );
  }
}

class CostCenterFilters extends Component {
  constructor(p) {
    super(p);
    this.state = { filter: p.filter, editing: false };

    this.renderTreeFilters = this.renderTreeFilters.bind(this);
    this.addCCFilter = this.addCCFilter.bind(this);
    this.removeCCFilter = this.removeCCFilter.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.save = this.save.bind(this);
  }

  renderTreeFilters(thisIndexFilter, thisIndexCCs, ccIndex) {
    const { editing } = this.state;

    if (!thisIndexFilter) {
      return <div className="ccTreeList">None</div>;
    }

    const selectedIDs = thisIndexFilter.values.map((v) => v.cost_center_id);
    if (!selectedIDs || selectedIDs.length === 0) {
      return <div className="ccTreeList">None</div>;
    }

    const selectedCCs = selectedIDs.map((id) => {
      const matchedCCs = thisIndexCCs.filter((cc) => cc.id === id);
      return matchedCCs !== null && matchedCCs.length === 1
        ? matchedCCs[0]
        : null;
    });

    return (
      <div className="ccTreeList">
        {selectedCCs.map((cc) => (
          <div className="ccName" key={`selecedccs${cc.id}`}>
            {cc.name}{" "}
            {editing && (
              <button
                onClick={() => this.removeCCFilter(cc.id, ccIndex)}
                className="addDelete delete"
              >
                <div>-</div>
              </button>
            )}
          </div>
        ))}
      </div>
    );
  }

  removeCCFilter(ccID, ccIndex) {
    const { filter } = this.state;
    const newFilterIndexes = filter.indexes.map((f) => {
      if (f.index !== ccIndex) {
        return f;
      }

      const newVals = f.values.filter((f) => f.cost_center_id !== ccID);
      return { index: ccIndex, values: newVals };
    });
    this.setState({ filter: { indexes: newFilterIndexes } });
  }

  addCCFilter(ccIndex, ccID) {
    if (ccID !== null) {
      const { filter } = this.state;

      let seenThisIndex = false;
      let newFilterIndexes = filter.indexes.map((f) => {
        if (f.index !== ccIndex) {
          return f;
        }
        seenThisIndex = true;
        const newVals = [{ cost_center_id: ccID }, ...f.values];
        return { index: ccIndex, values: newVals };
      });

      if (!seenThisIndex) {
        newFilterIndexes = [
          ...newFilterIndexes,
          { index: ccIndex, values: [{ cost_center_id: ccID }] },
        ];
      }

      this.setState({ filter: { indexes: newFilterIndexes } });
    }
  }

  cancelEditing() {
    const { filter } = this.props;

    this.setState({ editing: false, filter: filter });
  }

  save() {
    if (window.confirm("Are you sure you'd like to save these settings?")) {
      const { filter } = this.state;
      this.props.updateInstallation({
        employee_filters: { cost_centers: filter },
      });
    }
  }

  render() {
    const { costCenters } = this.props;
    const { filter, editing } = this.state;

    return (
      <div>
        {costCenters.map((cc) => {
          const filterVals =
            filter.indexes.filter((f) => f.index === cc.index) &&
            filter.indexes.filter((f) => f.index === cc.index)[0];

          const ccsAvailable = !isEmpty(cc.values)
            ? cc.values.filter((cc) => {
              let foundMatch = false;
              filterVals &&
                filterVals.values.forEach((filterval) => {
                  if (filterval.cost_center_id === cc.id) {
                    foundMatch = true;
                    return;
                  }
                });
              if (foundMatch) {
                return false;
              }
              return true;
            })
            : [];

          return (
            <div
              className="ccTreeCont"
              key={`ccfilter${cc.root_cost_center_id}`}
            >
              <div className="ccTreeName">
                <div className="name">{cc.tree_name}</div>{" "}
                {editing && (
                  <div className="addmore">
                    <span style={{ marginRight: "5px" }}>Add More:</span>{" "}
                    <AddCC
                      ccsAvailable={ccsAvailable}
                      onAdd={(newCCID) => this.addCCFilter(cc.index, newCCID)}
                    />
                  </div>
                )}
              </div>
              <div>
                {this.renderTreeFilters(filterVals, cc.values, cc.index)}
              </div>
            </div>
          );
        })}
        <div>
          {editing ? (
            <button onClick={() => this.cancelEditing()}>CANCEL</button>
          ) : (
            <button onClick={() => this.setState({ editing: !editing })}>
              EDIT
            </button>
          )}
          {editing && (
            <button onClick={() => this.save()} style={{ marginLeft: "20px" }}>
              SAVE CHANGES
            </button>
          )}
        </div>
      </div>
    );
  }
}

class InstallationFilters extends Component {
  constructor(p) {
    super(p);
    this.fetchCostCenters = this.fetchCostCenters.bind(this);
    this.updateInstallation = this.updateInstallation.bind(this);
  }

  componentWillMount() {
    const { costCenters } = this.props;

    if (costCenters === null) {
      this.fetchCostCenters();
    }
  }

  fetchCostCenters() {
    const { dispatch } = this.props;

    dispatch(fetchCompanyData("cost-centers"));
  }

  updateInstallation(updateBody) {
    const { installation, dispatch } = this.props;

    dispatch(updateInstallation(installation.token, updateBody));
  }

  render() {
    const {
      installation,
      costCenters,
      costCentersFetching,
      installIsUpdating,
    } = this.props;

    if (!installation) {
      return (
        <div className="InstallationFilters">
          <Loading />
        </div>
      );
    }

    return (
      <div className="InstallationFilters">
        <h1 className="">{`${installation.display_name} Filters`}</h1>
        <div className="head">Cost Centers</div>
        <div style={{ marginBottom: "20px" }}>
          All employees that have at least one of the following cost centers
          listed as their Default Cost Center will be able to punch in at this
          CloudPunch Installation.
        </div>
        <div>
          {costCentersFetching || installIsUpdating ? (
            <div>
              <Loading />
            </div>
          ) : !costCenters ? (
            <div>Failed to load Cost Centers</div>
          ) : (
            <div>
              <CostCenterFilters
                costCenters={costCenters}
                filter={installation.employee_filters.cost_centers}
                updateInstallation={this.updateInstallation}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToPropsFilters = (state, ownProps) => {
  const { costCenters, isFetching: cdFetching } = state.company_data;
  const { installIsUpdating } = state.cloudpunch;
  const costCentersFetching = cdFetching["cost-centers"];

  return {
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    installIsUpdating,
  };
};

const ConnectedInstallationFilters = connect(mapStateToPropsFilters)(
  InstallationFilters
);

class EmployeeDetail extends Component {
  render() {
    const { employee, deleteEnr } = this.props;

    if (employee === null) {
      return <div>Employee not found</div>;
    }

    return (
      <div className="EmployeeDetail">
        <h1>{`${employee.first_name} ${employee.last_name}`}</h1>
        {employee.enrollments && (
          <div>
            <div className="head">Fingerprint Enrollments</div>
            <div>
              {employee.enrollments.length === 0 ? (
                <div>
                  No fingerprint enrollments have synced from CloudPunch
                </div>
              ) : (
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col1">Finger</th>
                        <th className="col2">Status</th>
                        <th className="col3">Time Created</th>
                        <th className="col4"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {employee.enrollments.map((enr) => (
                        <tr
                          key={`enr${enr.id}`}
                          className={`enrrow ${enr.active && "active"}`}
                        >
                          <td className="col1">
                            {fingerPositionNames(enr.position)}
                          </td>
                          <td className="col2">
                            {enr.active ? "Active" : "Inactive"}
                          </td>
                          <td className="col3">{enr.created_date}</td>
                          <td className="col4 but">
                            {enr.active && (
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "This will remove this fingerprint from all relevant CloudPunch installations, are you sure you would like to proceed?"
                                    )
                                  )
                                    deleteEnr(enr.id);
                                }}
                                className="red"
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
/* eslint-disable */
const keyObj = {
  ["Early Punch"]: "early_punch_config",
  ["Early punch notification"]: "early_punch_config",
  ["Over time calculate"]: "over_time_notification",
  ["Overtime notification"]: "over_time_notification",
  ["Mealbreak time schedule"]: "mealbreak_time_schedule",
  ["Meal break period notification"]: "mealbreak_time_schedule",
  ["Mealbreak panalty pariod"]: "mealbreak_panalty_pariod",
  ["early_punch_config"]: "earlyPunchConfig",
  ["over_time_notification"]: "overTimeCalculate",
  ["mealbreak_time_schedule"]: "mealbreakTimeSchedule",
  ["mealbreak_panalty_pariod"]: "mealbreakPanaltyPariod",
};
/* eslint-enable  */

class ViewSettingTableRow extends Component {
  constructor(p) {
    super(p);
    this.state = {
      isOpen: false,
      isToggle: false,
      isEmail: true,
      isSms: false,
      isError: false,
    };
  }

  componentDidMount() {
    const { dataStatus } = this.props;
    this.setState({
      isEmail: dataStatus.email === "1" ? true : false,
      isSms: dataStatus.sms === "1" ? true : false,
      isToggle: dataStatus.st === "1" ? true : false,
    });
  }

  handleOpen = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleChange = (e) => {
    const { checked, name } = e.target;
    const { isEmail, isSms } = this.state;

    if (isEmail || isSms || checked) {
      this.setState({ isError: false });
    }

    this.setState({ [name]: checked });
  };

  handleToggle = (v) => {
    const { isEmail, isSms, isToggle } = this.state;

    if (isEmail || isSms) {
      this.props.handleCallViewSettings({
        key: this.props.name,
        st: !v ? "1" : "0",
        email: isEmail ? "1" : "0",
        sms: isSms ? "1" : "0",
      });
      this.setState({ isToggle: !isToggle });
    } else {
      this.setState({ isError: true, isOpen: true });
    }
  };

  render() {
    const { isOpen, isToggle, isEmail, isSms, isError } = this.state;
    const { name, description } = this.props;

    return (
      <>
        <tr>
          <td>{name}</td>
          <td>{description}</td>
          <td className="col4 but">
            <div className="d-flex">
              <ToggleButton
                inactiveLabel={"No"}
                activeLabel={"Yes"}
                value={isToggle}
                onToggle={this.handleToggle}
              />
              <span className="icon-plus" onClick={this.handleOpen}>
                {isOpen ? "-" : "+"}
              </span>
            </div>
          </td>
        </tr>
        {isOpen && (
          <tr>
            <td colspan={3}>
              <table>
                {isError && (
                  <tr>
                    <th colspan={3}>
                      <span>Email or sms one field is required.</span>
                    </th>
                  </tr>
                )}
                <tr>
                  <th>Email</th>
                  <th>
                    <input
                      type="checkbox"
                      name="isEmail"
                      checked={isEmail}
                      disabled={isToggle}
                      onChange={this.handleChange}
                    />
                  </th>
                </tr>
                <tr>
                  <th>SMS</th>
                  <th>
                    <input
                      type="checkbox"
                      name="isSms"
                      checked={isSms}
                      disabled={isToggle}
                      onChange={this.handleChange}
                    />
                  </th>
                </tr>
              </table>
            </td>
          </tr>
        )}
      </>
    );
  }
}

class ViewSettingModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggle: false,
      panalty: "0",
      isLoading: false,
      earlyPunchConfig: { email: "1", sms: "0", st: "0" },
      overTimeCalculate: { email: "1", sms: "0", st: "0" },
      mealbreakTimeSchedule: { email: "1", sms: "0", st: "0" },
      mealbreakPanaltyPariod: { st: "0" },
    };
    this.selectData = ["4", "5", "7", "8"];
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    const { response } = await this.props.dispatch(getCloudPunchSettings(356));

    Object.values(response).forEach((item) => {
      this.setState({ [keyObj[item.key_name]]: item.value });
    });

    this.setState({ isLoading: false });
  }

  handleToggle = (val) => {
    const { handleCallViewSettings } = this.props;
    handleCallViewSettings({
      key: "Mealbreak time schedule",
      st: this.state.mealbreakTimeSchedule.st === "0" ? "1" : "0",
    });
    this.setState({
      mealbreakTimeSchedule: {
        st: this.state.mealbreakTimeSchedule.st === "0" ? "1" : "0",
      },
    });
  };

  handleSelectChange = (e) => {
    const { handleCallViewSettings } = this.props;
    const { value } = e.target;
    handleCallViewSettings({
      key: "Mealbreak panalty pariod",
      st: value + "",
    });
    this.setState({ mealbreakPanaltyPariod: { st: value + "" } });
  };

  render() {
    const { handleCallViewSettings } = this.props;
    const {
      isLoading,
      mealbreakPanaltyPariod,
      mealbreakTimeSchedule,
      earlyPunchConfig,
      overTimeCalculate,
    } = this.state;

    return (
      <div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <h1>Settings</h1>
            <div className="head"></div>
            <table className="table">
              <thead>
                <tr>
                  <th className="col1">Notification</th>
                  <th className="col1">Description</th>
                  <th className="col2">Action</th>
                </tr>
              </thead>
              <tbody>
                <ViewSettingTableRow
                  name={"Early punch notification"}
                  handleCallViewSettings={handleCallViewSettings}
                  dataStatus={earlyPunchConfig}
                  description={
                    "Employee early punch warning send to the manager."
                  }
                />
                <ViewSettingTableRow
                  handleCallViewSettings={handleCallViewSettings}
                  name={"Overtime notification"}
                  dataStatus={overTimeCalculate}
                  description={
                    "Notice employee overtime for the admin and manager."
                  }
                />
                <ViewSettingTableRow
                  handleCallViewSettings={handleCallViewSettings}
                  name={"Meal break period notification"}
                  dataStatus={mealbreakTimeSchedule}
                  description={"Meal break period alert for the employee."}
                />
                {/* <tr>
                  <td>Meal break period notification</td>
                  <td>Meal break period alert for the employee.</td>
                  <td className="col4 but">
                    <div className="d-flex">
                      <ToggleButton
                        inactiveLabel={"No"}
                        activeLabel={"Yes"}
                        value={mealbreakTimeSchedule.st === "1" ? true : false}
                        onToggle={this.handleToggle}
                      />
                      <span className="icon-plus visibility-none"></span>
                    </div>
                  </td>
                </tr> */}
                <tr>
                  <td>Meal break penalty period</td>
                  <td>
                    Meal break start after penalty period from the start time.
                  </td>
                  <td className="col4 but">
                    <select
                      className="mealBrackSelect"
                      onChange={this.handleSelectChange}
                      value={mealbreakPanaltyPariod.st}
                    >
                      {this.selectData.map((op) => (
                        <option value={op}>{op}h</option>
                      ))}
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
      </div>
    );
  }
}

class CloudPunchBody extends Component {
  constructor(p) {
    super(p);
    this.launchEnrollmentsModal = this.launchEnrollmentsModal.bind(this);
    this.launchInstallationModal = this.launchInstallationModal.bind(this);
    this.empToggleActive = this.empToggleActive.bind(this);

    this.state = {
      viewSettingModelShow: false,
      installationId: "",
      rowPerPage: 10,
      currentPage: 0,
      installationSetupData: [],
    };
  }

  componentWillMount() {
    const { beenLoadedOnce, dispatch } = this.props;

    if (!beenLoadedOnce) {
      dispatch(fetchCPInfo());
    }

    const { token } = getToken();
    if (token) {
      this.setState({ token: token });
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    if (!localStorage.getItem("viewSettingsUpdateAt")) {
      localStorage.setItem("viewSettingsUpdateAt", new Date());
    }
    this.fetchInstallationSetup();
    dispatch(userWritePermission());
  }

  fetchInstallationSetup = async () => {
    try {
      const { token, userToken } = getToken();
      const response = await fetch(`/api/tlm/cp/installations/setup`, {
        headers: {
          Opusertoken: userToken,
          Authorization: token,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        this.setState({ installationSetupData: data });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  launchEnrollmentsModal(empID) {
    const { match, history } = this.props;

    history.push(`${match.url}/employee/${empID}`);
  }

  launchInstallationModal(insID, type) {
    const { match, history } = this.props;

    history.push(`${match.url}/installation/${insID}/${type}`);
  }

  launchInstallationSettingsModal(insID, type) {
    const { match, history } = this.props;

    history.push(`${match.url}/installation/${insID}/${type}`);
  }

  launchInstallationSetupModal(insID, type) {
    const { match, history } = this.props;

    history.push(`${match.url}/installation/${insID}/${type}`);
  }

  empToggleActive(accID, oldActive, fname) {
    let stment =
      "Are you sure you'd like to inactivate " +
      fname +
      "? This will remove all of their enrollments from every CloudPunch installation.";
    if (!oldActive) {
      stment = "Are you sure you'd like to activate " + fname + "?";
    }
    if (window.confirm(stment)) {
      const { dispatch } = this.props;
      dispatch(updateEmployee(accID));
    }
  }

  handleViewSettingsModel = () => {
    this.setState({ viewSettingModelShow: true });
  };

  handleCallViewSettings = async ({ key, st, ...other }) => {
    const body = {
      company_op_id: this.props.companyInfo.op_id,
      installation_id: 356,
      config_data: [
        {
          key_name: keyObj[key],
          value: {
            st,
            ...other,
          },
        },
      ],
    };

    await this.props.dispatch(cloudPunchSettings(body));
    localStorage.setItem("viewSettingsUpdateAt", new Date());
  };

  handlePageClick = ({ selected: selectedPage }) => {
    this.setState({ currentPage: selectedPage });
  };

  render() {
    const {
      allFetching,
      installations,
      employees,
      dispatch,
      installsFetching,
      empsAreFetching,
      history,
      match,
      companyInfo,
    } = this.props;
    const {
      token,
      viewSettingModelShow,
      installationId,
      rowPerPage,
      currentPage,
      installationSetupData,
    } = this.state;

    const pageCount = Math.ceil(installations.length / rowPerPage);

    const offset = currentPage * rowPerPage;
    var CloudPunchSetup64 = installationSetupData.length !== 0 ? installationSetupData[0].url : "";
    var CloudPunchSetup86 = installationSetupData.length !== 0 ? installationSetupData[1].url : "";
    
    console.log({installationSetupData});
    
    return (
      <div className="CloudPunchBody pis">
        {viewSettingModelShow && (
          <Modal
            closeModal={() => this.setState({ viewSettingModelShow: false })}
          >
            <ViewSettingModel
              installationId={installationId}
              dispatch={dispatch}
              handleCallViewSettings={this.handleCallViewSettings}
            />
          </Modal>
        )}
        <Route
          path={`${match.url}/employee/:empID`}
          render={(props) => (
            <Modal closeModal={() => history.push(match.url)}>
              <div>
                <EmployeeDetail
                  deleteEnr={(id) => dispatch(deleteCPEnrollment(id))}
                  empID={props.match.params.empID}
                  employee={
                    employees.filter(
                      (e) => e.id === Number(props.match.params.empID)
                    ).length !== 0
                      ? employees.filter(
                        (e) => e.id === Number(props.match.params.empID)
                      )[0]
                      : null
                  }
                />
              </div>
            </Modal>
          )}
        />
        <Route
          path={`${match.url}/installation/:insID/filters`}
          render={(props) => (
            <Modal closeModal={() => history.push(match.url)}>
              <div>
                <ConnectedInstallationFilters
                  insID={props.match.params.insID}
                  installation={
                    installations.filter(
                      (e) => e.id === Number(props.match.params.insID)
                    ).length !== 0
                      ? installations.filter(
                        (e) => e.id === Number(props.match.params.insID)
                      )[0]
                      : null
                  }
                />
              </div>
            </Modal>
          )}
        />
        <Route
          path={`${match.url}/installation/:insID/settings`}
          render={(props) => (
            <Modal closeModal={() => history.push(match.url)}>
              <CloudPunchInstallationSettings />
            </Modal>
          )}
        />
        <Route
          path={`${match.url}/installation/:insID/settings/logs`}
          render={(props) => (
            <Modal closeModal={() => history.push(match.url)}>
              <InstallationLogs />
            </Modal>
          )}
        />
        <Route
          path={`${match.url}/installation/:insID/setup`}
          render={(props) => (
            <Modal closeModal={() => history.push(match.url)}>
              <CloudPunchSetup />
            </Modal>
          )}
        />
        {allFetching ? (
          <Loading />
        ) : (
          <>
            <div>
              <div className="section installations">
                {installsFetching && (
                  <div className="loadCont">
                    <Loading />
                  </div>
                )}
                <h2>
                  Installations ({installations.length})
                  <button
                    onClick={() => this.props.dispatch(fetchCPInstalls())}
                  >
                    REFRESH
                  </button>
                </h2>

                <div className="tableConts">
                  {installations && installations.length === 0 ? (
                    <>
                      <div className="noapps">
                        No installations have synced yet
                      </div>
                      <div className="header-action">
                        <Select className="sort-select" name="" id="">
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                        </Select>
                        <input type="search" placeholder="Search" />
                      </div>
                    </>
                  ) : (
                    <table className="table">
                      <thead>
                        <tr className="installation-tablerow">
                          <th className="col1">Name</th>
                          <th className="col2">Version</th>
                          <th className="col2">Last Synced</th>
                          <th className="col3">Last IP</th>
                          <th className="col3">Last Punch</th>
                          <th className="col2">Status</th>
                          <th className="col3">Running Status</th>
                          <th className="col3">USB Status</th>
                          <th className="col2">Actions</th>
                          {/* <th className="col4">Employee Filters</th>
                          <th className="col5">Settings</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {installations
                          .slice(offset, offset + rowPerPage)
                          .map((i, Installationindex) => (
                            <tr key={`installs${i.id}`}>
                              <td className="col1">{i.display_name}</td>
                              <td className="col3">{i.version}</td>
                              <td className="col3">
                                {i.last_synced === "" ? "N/A" : i.last_synced}
                              </td>
                              <td className="col3">
                                {i.ip === "" ? "N/A" : i.ip}
                              </td>
                              <td className="col3">
                                {i.last_running_date === ""
                                  ? "N/A"
                                  : i.last_running_date}
                              </td>
                              <td className="col3">
                                {i.active === 1 ? (
                                  <div class="circle-flex">
                                    <div
                                      class="circle-blue"
                                      title="Installation Active"
                                    ></div>
                                  </div>
                                ) : (
                                  <div class="circle-flex">
                                    <div
                                      class="circle-red"
                                      title="Installation Inactive"
                                    ></div>
                                  </div>
                                )}
                              </td>
                              <td className="col3">
                                {i.running_status === 1 ? (
                                  <div class="circle-flex">
                                    <div
                                      class="circle-blue-animation"
                                      title="Installation Running"
                                    ></div>
                                  </div>
                                ) : (
                                  <div class="circle-flex">
                                    <div
                                      class="circle-red"
                                      title="Installation not Running"
                                    ></div>
                                  </div>
                                )}
                              </td>
                              <td className="col3">
                                {i.usb_active === 1 ? (
                                  <div class="circle-flex">
                                    <div
                                      class="circle-blue"
                                      title="USB Connected"
                                    ></div>
                                  </div>
                                ) : (
                                  <div class="circle-flex">
                                    <div
                                      class="circle-red"
                                      title="USB Not Connected"
                                    ></div>
                                  </div>
                                )}
                              </td>
                              {/* <td className="col3"><div class=""> {i.running_status ==1 ? <div class="circle-flex-main"><div class="circle-blue-animation" title="Installation Running"></div></div> : <div class="circle-flex-main"><div class="circle-red-animation" title="Installation not Running"></div></div>}{i.active ==1 ? <div class="circle-flex"><div class="circle-blue" title="Installation Active"></div></div> : <div class="circle-flex"><div class="circle-red" title="Installation Inactive"></div></div>}</div></td> */}
                              {/* <td className="col3">{i.running_status ==1 ? <div class="circle-flex-main"><div class="circle-blue-animation" title="Installation Running"></div></div> : <div class="circle-flex-main"><div class="circle-red-animation" title="Installation not Running"></div></div>}</td> */}

                              <td className="col3 ">
                                <div className="actions">
                                  <div className="actions-btn">
                                    {/* <button className="btn">
                                      {" "}
                                      <FontAwesomeIcon icon={solid("eye")} />
                                    </button> */}
                                  </div>
                                  <div className="actions-btn">
                                    <button
                                      onClick={() =>
                                        this.launchInstallationModal(
                                          i.id,
                                          "filters"
                                        )
                                      }
                                    >
                                      View Filters
                                    </button>
                                  </div>
                                  <div className="actions-btn">
                                    <button
                                      onClick={() =>
                                        this.launchInstallationSettingsModal(
                                          i.id,
                                          "settings"
                                        )
                                      }
                                    >
                                      View Settings
                                    </button>
                                  </div>
                                </div>
                              </td>

                              {/* <td className="col4 but">
                              <button
                                onClick={() => this.launchInstallationModal(i.id, "filters")}
                              >
                                View Filters
                              </button>
                            </td>
                            <td className="col5 but">
                              <button>View Settings</button>
                            </td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next"
                    pageCount={pageCount}
                    onPageChange={this.handlePageClick}
                    previousLabel="Previous"
                    renderOnZeroPageCount={null}
                    containerClassName={"pagination-container"}
                    previousLinkClassName={"pagination__link"}
                    nextLinkClassName={"pagination__link"}
                    disabledClassName={"disabled"}
                    activeClassName={"active"}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={1}
                  />
                </div>
              </div>
            </div>
            <div className="section employees">
              {empsAreFetching && (
                <div className="loadCont">
                  <Loading />
                </div>
              )}
              <h2>
                Employees{" "}
                <button onClick={() => this.props.dispatch(fetchCPEmps())}>
                  REFRESH
                </button>
              </h2>
              <div className="tableCont">
                {employees && employees.length === 0 ? (
                  <div className="noapps">
                    No employees have been enrolled yet
                  </div>
                ) : (
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col1">Name</th>
                        <th className="col2 enr">Employee ID</th>
                        <th className="col3 enr">Enrolled Fingerprints</th>
                        <th className="col4 enr">Active</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.map((i) => (
                        <tr key={`employees${i.id}`}>
                          <td className="col1">
                            {`${i.first_name}${i.middle_initial
                              ? " " + i.middle_initial + "."
                              : ""
                              } ${i.last_name}`}
                          </td>
                          <td className="col2 but">{i.employee_id}</td>
                          <td className="col3 but">
                            {i.enrollments &&
                              (i.enrollments.length === 0 ? (
                                "None"
                              ) : (
                                <button
                                  onClick={() =>
                                    this.launchEnrollmentsModal(i.id)
                                  }
                                >
                                  <span style={{ marginRight: "5px" }}>
                                    {`${i.enrollments.filter(
                                      (e) => e.active === true
                                    ).length
                                      } Active`}
                                    ,
                                  </span>
                                  <span>
                                    {`${i.enrollments.filter(
                                      (e) => e.active === false
                                    ).length
                                      } Inactive`}
                                  </span>
                                </button>
                              ))}
                          </td>
                          <td className="col4 but">
                            {
                              <ToggleButton
                                inactiveLabel={"No"}
                                activeLabel={"Yes"}
                                value={i.active}
                                onToggle={(value) => {
                                  this.empToggleActive(
                                    i.account_id,
                                    value,
                                    i.first_name
                                  );
                                }}
                              />
                            }
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
            <div className="section employees">
              <h2>Company Settings</h2>
              <div className="tableCont">
                <table className="table">
                  <thead>
                    <tr>
                      <th className="col1">Company</th>
                      <th className="col2 enr">Updated At</th>
                      <th className="col4 enr">Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="col1">{companyInfo.name}</td>
                      <td className="col2 but">
                        {moment(
                          localStorage.getItem("viewSettingsUpdateAt")
                        ).format("DD/MM/YYYY hh:mm a")}
                      </td>
                      <td className="col4 but">
                        <button onClick={() => this.handleViewSettingsModel()}>
                          View Settings
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="section">
              <HideShow
                label={<h2>Windows Application Installation</h2>}
                body={
                  <div className="install">
                    {this.props.userAdminPermission &&
                      <div className="section employees">
                        <div className="mb-1 uploadprocess-wrapper">
                          <h4 className="mb-1">Setup Upload Process</h4>
                          <button
                            className="newsetupup-btn"
                            onClick={() =>
                              this.launchInstallationSetupModal(298, "setup")
                            }
                          >
                            Upload New Setup
                          </button>
                        </div>

                        <div div className="tableCont">
                          <table className="table">
                            <thead>
                              <tr className="installation-tablerow">
                                <th className="col1">Setup Name </th>
                                <th className="col2">Version</th>
                                <th className="col3 enr">Updated At</th>
                                <th className="col4 enr">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {installationSetupData.map((item, index) => {
                                if (index === 0) {
                                  CloudPunchSetup64 = item.url;
                                } else if (index === 1) {
                                  CloudPunchSetup86 = item.url;
                                }
                                return (
                                  <tr>
                                    <td className="col1">{item.name}</td>
                                    <td>{item.version}</td>
                                    <td className="col2 but">
                                      {item.updated_at}
                                    </td>
                                    <td className="col4 but">
                                      <button>Remove</button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    }


                    <div className="step">
                      <h4 className="setupdlhd">Setup Download Process</h4>
                      <div className="head">Step 1</div>
                      <div className="stepBody">
                        <p>
                          Download and install the fingerprint reader runtime
                          environment (RTE) and CloudPunch. This only needs to
                          be installed once on your target machine. If you are
                          updating CloudPunch and already installed the RTE
                          previously, skip this step.
                        </p>
                        <p>
                          Click one of the links below to download the RTE's
                          installer files. Choose between x86 and x64 depending
                          on your target machine's operating system.
                        </p>
                        <p className="downloadlinks">
                          <button className="btn-cloudpunch-download"
                            onClick={() =>
                              window.open(CloudPunchSetup64, "_blank")
                            }
                          >
                            Download RTE & CloudPunch (x64 RTE)
                          </button>

                          <button className="btn-cloudpunch-download"
                            onClick={() =>
                              window.open(CloudPunchSetup86, "_blank")
                            }
                          >
                            Download RTE & CloudPunch (x86 RTE)
                          </button>
                        </p>
                        <p>
                          Unzip the file and run the <b>setup.msi</b> installer
                          file. This will launch a Windows Installer dialogue
                          that will install everything needed to integrate your
                          fingerprint reader device with Windows.
                        </p>
                      </div>
                    </div>
                    {/* <div className="step">
                  <div className="head">
                    Step 2
                  </div>
                  <div className="stepBody">
                    <p>
                      Download and install CloudPunch.
                    </p>
                    <p>
                      Click the link below to download the newest version of CloudPunch.
                    </p>
                    <p className="downloadlinks">
                      <a href="https://storage.googleapis.com/cloudpunch/versions/latest/CloudPunchInstaller.msi" target="_blank" rel="noopener noreferrer">
                        Download CloudPunch
                      </a>
                    </p>
                    <p>
                      Run the <b>CloudPunchInstaller.msi</b> file, this will launch a Windows Installer dialogue. Complete the install and a shortcut should appear on your desktop. Double click the CloudPunch shortcut to launch the application.
                    </p>
                  </div>
                </div> */}
                    <div className="step">
                      <div className="head">Step 2</div>
                      <div className="stepBody">
                        <p>
                          If this is the first time running CloudPunch on this
                          machine, a setup dialogue will launch upon launching
                          the application. To complete, you will need the
                          following values:
                        </p>
                        <p>
                          Your company's Access Token: {token}
                          <br />
                          Default admin password: admin@1234
                          <br />
                          Default manager password (not needed for dialogue, but
                          useful later): manager@1234
                        </p>
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          </>
          /* <div style={{ marginTop: 20 }}>
            <h3>View Settings</h3>
            <button onClick={() => this.handleViewSettingsModel({ id: 356 })}>
              View Settings
            </button>
          </div> */
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cp_installations, cp_employees, cp_enrollments } = state.entities;
  const { companyInfo } = state.products;
  const {
    beenLoadedOnce,
    allFetching,
    installationsList,
    employeesList,
    installsFetching,
    empsAreFetching,
  } = state.cloudpunch;
  const { costCenters, isFetching: cdFetching } = state.company_data;
  const costCentersFetching = cdFetching["cost-centers"];

  const instsListJoin =
    installationsList === undefined
      ? []
      : installationsList.map((id) => {
        let thisIn = Object.assign({}, cp_installations[id]);
        return thisIn;
      });

  const empsListJoin =
    employeesList === undefined
      ? []
      : employeesList.map((id) => {
        let thisIn = Object.assign({}, cp_employees[id]);
        thisIn.enrollments = thisIn.enrollments.map((id) => {
          {
            let thisEn = Object.assign({}, cp_enrollments[id]);
            return thisEn;
          }
        });
        return thisIn;
      });

  const {
    userAdminPermission,
  } = state.dashboard;


  return {
    companyInfo,
    beenLoadedOnce,
    allFetching,
    installations: instsListJoin,
    employees: empsListJoin,
    installsFetching,
    empsAreFetching,
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    userAdminPermission
  };
};

export default withRouter(connect(mapStateToProps)(CloudPunchBody));
