import React from "react";
import logo from "../assets/images/op-logo-cloud.png";
import Loading from "../components/ui/Loading";

const LoginContainer = () => {
  const error = "";
  const loginFetching = false;

  return (
    <div className="login-container">
      <div className="login-box">
        <div className="login-logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="header">
          {true
            ? "Enter your admin account credentials and the Short Name of the company you want to login as"
            : "Login to your OnePoint HCM account"}
        </div>

        {error !== "" && <div className="error">{error}</div>}

        {loginFetching ? (
          <Loading />
        ) : (
          <div>
            <div className="form">
              <div className="row mb-2 my-flex my-justify-content-center">
                <div>
                  <input type="radio" id="User" name="role" checked />
                  <label htmlFor="User">User</label>
                </div>
                <div className="ms-4">
                  <input type="radio" id="Admin" name="role" />
                  <label htmlFor="Admin">Admin</label>
                </div>
              </div>

              <div className="row mb-2">
                <div className="label">Company Short Name</div>
                <div className="one-input">
                  <input
                    type="text"
                    placeholder="Company Short Name"
                    autoFocus={true}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="label">Username</div>
                <div className="one-input">
                  <input type="text" placeholder="Username" autoFocus={true} />
                </div>
              </div>
              <div className="row mb-2">
                <div className="label">Password</div>
                <div className="one-input">
                  <input type="text" placeholder="Password" autoFocus={true} />
                </div>
              </div>

              <div>
                <button className="login-btn">Login</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginContainer;
