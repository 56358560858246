import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from "../../../components/ui/Loading";
import { isEmpty } from "lodash";

import { saveSettings } from "../../../actions/integrations";
import { accountTimingOption, options } from "../../../constants/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import CostCenterFilterSetting from "../../../components/settings/CostCenterFilterSetting";
import AccessControlIntegration from "./AccessControlIntegration";
import SyncTrainingHistoryIntegration from "./SyncTrainingHistoryIntegration";

const IntegrationSettings = (props) => {
  const {
    settingsToggle,
    integration,
    dispatch,
    settingsAreSaving,
    handleIntegrationDoc,
    handleSyncEmployeeProcess,
    syncLoading,
    integrationName,
  } = props;
  const [employeeSyncValue, setEmployeeSyncValue] = useState("hired");
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState(null);
  const [settingsCopy, setSettingsCopy] = useState(null);

  const handleEmployeeChange = (event) => {
    const { value } = event.target;
    setEmployeeSyncValue(value);
  };

  const handleCheckSaveSetting = () =>
    JSON.stringify(settingsCopy) !== JSON.stringify(settings);

  const handleSyncEmployee = async () => {
    try {
      setIsLoading(true);
      const type = employeeSyncValue === "hired" ? 1 : 2;
      handleSyncEmployeeProcess(type, employeeSyncValue);
      // const payloadEmpSync = {
      //   endPoint: `api/emp/custom/${type}`,
      //   method: "get",
      // };
      // const payloadEmpProcess = {
      //   endPoint: `api/emp/hf/process/${""}/${type}`,
      //   method: "get",
      // };
      // const payloadEmpEventProcess = {
      //   endPoint: "",
      //   method: "get",
      // };
      // await dispatch(integrationEmployeeSync(employeeSyncValue));
      // await dispatch(
      //   integrationEmployeeHFProcess(integration.integration_id, type)
      // );
      // await dispatch(
      //   integrationEmployeeEventProcess(integration.integration_id, type)
      // );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    const copy = JSON.parse(JSON.stringify(settings));
    if (name === "enabled") {
      copy[name] = value === "true" ? true : false;
    }
    if (name === "hired_timing") {
      copy[name].choice = value;
    }
    if (name === "days") {
      copy["hired_timing"].days = value;
    }
    if (name === "email_name") {
      copy["email_name"].choice = value;
    }
    if (name === "domain_name") {
      copy["email_name"].domain_name = value;
    }
    setSettings(copy);
  };

  const handleGenericChange = (key, value) => {
    const copy = JSON.parse(JSON.stringify(settings));
    copy[key] = value;
    setSettings(copy);
  };

  const handleSaveSettings = async () => {
    setIsLoading(true);
    await dispatch(saveSettings(settings, integration.url));
    setIsLoading(false);
  };

  const renderSaveAlert = (settingsProp, settingsState) => {
    return (
      handleCheckSaveSetting() && (
        <div className="saveAlert">
          <span className="box"></span>
          <span className="mx-1">=</span> Unsaved Setting
        </div>
      )
    );
  };
  const renderSaveButtons = (settingsProp, settingsState) => {
    return (
      handleCheckSaveSetting() && (
        <div className="mt-2">
          <button className="my-btn" onClick={() => { }}>
            CANCEL
          </button>
          <button className="ms-2 my-btn" onClick={handleSaveSettings}>
            SAVE CHANGES
          </button>
        </div>
      )
    );
  };

  const renderSettings = (key, setting) => {
    switch (key) {
      case "enabled":
        return (
          <div key={key} className="product-setting-inner mt-2">
            <label>ON/OFF</label>
            <div>
              <p className="mt-0 mb-0">Integration ENABLED or DISABLED:</p>
              <select
                value={setting ? "true" : "false"}
                onChange={handleChange}
                name="enabled"
              >
                <option value={"true"}>ENABLED</option>
                <option value={"false"}>DISABLED</option>
              </select>
              <p className="m-0">
                Setting to DISABLED will halt all actions involving G Suite
              </p>
            </div>
          </div>
        );

      case "hired_timing":
        return (
          <div key={key} className="product-setting-inner mt-2">
            <label>New Account Timing</label>
            <div>
              <select
                value={setting.choice}
                name="hired_timing"
                onChange={handleChange}
              >
                {accountTimingOption.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {setting.choice[setting.choice.length - 1] === "n" && (
                <div className="mt-2 n-days-in">
                  <input
                    type="number"
                    value={setting.days}
                    name="days"
                    onChange={handleChange}
                    placeholder="Days"
                  />
                </div>
              )}
              <p className="m-0">
                Select when you want new new hires' accounts to be provisioned
                relative to their Start and Hired Dates in OnePoint.
              </p>
            </div>
          </div>
        );

      case "email_name":
        return (
          <div key={key} className="product-setting-inner mt-2">
            <label>New Email Address Settings</label>
            <div>
              <p className="m-0 mb-0">New Email Address Format:</p>
              <p className="m-0 mb-0">example: jane.doe@yourcompany.com</p>
              <select
                value={setting.choice}
                name="email_name"
                onChange={handleChange}
              >
                {options.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {(setting.choice === "adjacent" || setting.choice === "period" || setting.choice === "op_username" || setting.choice === "first_initial") ? (
                <div className="my-flex mt-2 ">
                  <p className="m-0">Domain Name to Use:</p>
                  <div>
                    <input
                      type="text"
                      value={setting.domain_name}
                      name="domain_name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ) : ""}

              <p className="m-0">
                Note: this domain name must be connected to G Suite in order to
                successfully create an email address
              </p>
            </div>
          </div>
        );

      case "cost_center_filter":
        return (
          <div key={key} className="product-setting-inner mt-2">
            <label>Cost Center Filters</label>
            <div>
              <p>
                Limit which employee groups get {integrationName} accounts
                provisioned. Note: you must add the employee to the cost center
                before hiring in order for this filter to take effect
              </p>

              <CostCenterFilterSetting
                onChange={(newVal) =>
                  handleGenericChange("cost_center_filter", newVal)
                }
                setting={setting}
              />
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isEmpty(integration)) {
      setSettings(integration.settings);
      setSettingsCopy(integration.settings);
    }
  }, []);
  return (
    <div
      className={`product-right-content ${settingsToggle ? "setting-show" : "setting-hide"
        }`}
    >
      <div className={`settings sect `}>
        <div className="setting_heading">
          <h2>Integration Setting</h2>
        </div>
        {isLoading ||
          isEmpty(integration) ||
          isEmpty(settings) ||
          settingsAreSaving ? (
          <Loading />
        ) : (
          <>
            {renderSaveAlert(settingsCopy, settings)}
            <div className="product-setting-inner">
              <label>Sync new employee</label>
              <div>
                <select
                  onChange={handleEmployeeChange}
                  defaultValue={employeeSyncValue}
                >
                  <option value="hired">New Hired</option>
                  <option value="fired">New Fired</option>
                </select>
                <button
                  disabled={syncLoading}
                  onClick={handleSyncEmployee}
                  className="ms-1"
                >
                  sync
                </button>
              </div>
              <p>
                Select when you want new hires / fired' accounts to be
                provisioned for company integrations.
              </p>
            </div>
            {Object.keys(integration.settings).map((key) => (
              <div
                key={key}
                className={`${JSON.stringify(settingsCopy[key]) !==
                    JSON.stringify(settings[key])
                    ? "unsave"
                    : ""
                  }`}
              >
                {renderSettings(key, settings[key])}
              </div>
            ))}
            {/* <div className="product-setting-inner">
              <label htmlFor="">Cost Center Filters</label>
              <div>
                <p>
                  Limit which employee groups get G Suite accounts provisioned.
                  Note: you must add the employee to the cost center before
                  hiring in order for this filter to take effect
                </p>
                <select>
                  <option value="true">Provision all employees</option>
                  <option value="false">
                    Provision employees in selected cost centers only
                  </option>
                </select>
                <button>sync</button>
              </div>
            </div>
            <div className="product-setting-inner mt-2">
              <label>New Email Address Settings</label>
              <div>
                <p className="mt-2 mb-0">New Email Address Format</p>
                <select>
                  <option value="use_op">
                    Use Primary Email from OnePoint (must include at time of
                    hire)
                  </option>
                  <option value="adjacent">Autogenerate using pattern:</option>
                  <option value="period">Autogenerate using pattern: </option>
                  <option value="first_initial">
                    Autogenerate using pattern:
                  </option>
                  <option value="op_username">
                    Use Username from OnePoint:
                  </option>
                </select>
                <p>example: jane.doe@yourcompany.com</p>
              </div>
            </div> */}
            {renderSaveButtons(settingsCopy, settings)}
          </>
        )}
        {integrationName === 'mineral' ? <AccessControlIntegration /> : ''}
        {integrationName === 'biz_library' ? <SyncTrainingHistoryIntegration /> : ''}
      </div>
      <button className="my-btn mt-2" onClick={handleIntegrationDoc}>
        Integration document
        <span className="ms-2">
          <FontAwesomeIcon icon={solid("file-lines")} />
        </span>
      </button>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { integrations } = state.entities;
  const { match } = ownProps;
  const { settingsAreSaving } = state.integrations;

  const { integrationName } = match.params;

  return {
    integration: integrations[integrationName],
    settingsAreSaving,
    integrationName,
  };
};

export default memo(withRouter(connect(mapStateToProps)(IntegrationSettings)));
